const { REACT_APP_API_URL_BASE, REACT_APP_MAJOR_BUILD_VERSION, REACT_APP_MINOR_BUILD_VERSION, REACT_APP_BUILD_NUMBER, REACT_APP_MERCADO_PAGO_PUBLIC_KEY } = process.env;

const config = {
    apiUrlBase: REACT_APP_API_URL_BASE,
    majorBuildVersion: Number(REACT_APP_MAJOR_BUILD_VERSION),
    minorBuildVersion: Number(REACT_APP_MINOR_BUILD_VERSION),
    buildNumber: Number(REACT_APP_BUILD_NUMBER),
    mercadoPagoPublicKey: REACT_APP_MERCADO_PAGO_PUBLIC_KEY
}

export default config;
import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';

// Animations
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';

// Routing
import history from './routes/history';
import CustomRoute from './routes/route';

// Components
import Footer from './components/footer';
import AppBar from './components/appBar';

// Context
import { useUserContext } from './context/authentication';

// Pages
import LoginPage from './pages/login';
import MFAPage from './pages/mfa';
import ForgotPasswordPage from './pages/forgotPassword';
import RegisterPage from './pages/register';
import AcceptTermosPage from './pages/acceptTerms';
import DashboardPage from './pages/dashboard';
import AboutPage from './pages/about';
import ProfilePage from './pages/profile';
import AddressesPage from './pages/addresses';
import ChangePasswordPage from './pages/changePassword';
import ChannelSelectPage from './pages/channelSelect';
import UserSelectPage from './pages/userSelect';
import SubscriptionPage from './pages/subscription'
import InvitationPage from './pages/invitation';

// Hooks
import useIsAlive from './hooks/useIsAlive';

const App: React.FunctionComponent<{}> = function () {
    const { authenticatedUser } = useUserContext();
    useIsAlive();

    return (
        <>
            {authenticatedUser?.isAuthenticated && <AppBar />}
            <Router history={history}>
                <Route render={({ location }) => (
                    <TransitionGroup>
                        <CSSTransition key={location.key} timeout={1000} classNames="fade">
                            <Switch location={location}>
                                <CustomRoute exact path="/" component={LoginPage} title="Login" />
                                <CustomRoute exact path="/mfa" component={MFAPage} title="Autenticação Múltiplo Fator" />
                                <CustomRoute exact path="/forgot-password" component={ForgotPasswordPage} title="Recuperar Senha" />
                                <CustomRoute exact path="/register" component={RegisterPage} title="Solicitar Acesso" />
                                <CustomRoute exact path="/invitation/:idConvite" component={InvitationPage} title="Convite" />

                                {/* Necessita autenciação */}
                                <CustomRoute exact path="/dashboard" component={DashboardPage} title="Home" isPrivate />
                                <CustomRoute exact path="/profile" component={ProfilePage} title="Meu Perfil" isPrivate />
                                <CustomRoute exact path="/addresses" component={AddressesPage} title="Meus Endereços" isPrivate />
                                <CustomRoute exact path="/subscription" component={SubscriptionPage} title="Minha Assinatura" isPrivate />
                                <CustomRoute exact path="/change-password" component={ChangePasswordPage} title="Alterar Senha" isPrivate />
                                <CustomRoute exact path="/about" component={AboutPage} title="Sobre o Portal PBI" isPrivate />
                                <CustomRoute exact path="/channel-select" component={ChannelSelectPage} title="Selecione o Canal" isPrivate />
                                <CustomRoute exact path="/user-select" component={UserSelectPage} title="Selecione o usuário" isPrivate />
                                <CustomRoute exact path="/accept-terms" component={AcceptTermosPage} title="Termos de Aceite" isPrivate />
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                )} />
            </Router>
            <Footer />
        </>
    )
}

export default App;

function cnpj(value: string) {
    if (!value) {
        return value;
    }

    value = value.replace(/\D/g, "")                           //Remove tudo o que não é dígito
    value = value.replace(/^(\d{2})(\d)/, "$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    value = value.replace(/\.(\d{3})(\d)/, ".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    value = value.replace(/(\d{4})(\d)/, "$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    return value
}

function cpf(value: string) {
    if (!value) {
        return value;
    }

    value = value.replace(/\D/g, "")                    //Remove tudo o que não é dígito
    value = value.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    value = value.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos de novo (para o segundo bloco de números)
    value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return value
}


function moneyFormat(value?: number, prefix?: string) {
    return value ? `${prefix || ''} ${value.toFixed(2).replace(/\./g, ',')}` : '';
}

export {
    cnpj,
    cpf,
    moneyFormat
}
import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import IUserContext from "../interfaces/userContext";
import api from "../api";
import sAuthentication from "../services/authenticationService";
import sSubscription from "../services/subscriptionService";
import sUser from "../services/userService";
import IUser from "../interfaces/user";
import history from "../routes/history";
import { storageParams, userType } from "../config/enum";
import { useSubscriptionContext } from "./subscription";

const UserContext = createContext<IUserContext | undefined>(undefined);

const AuthenticationContextProvider: React.FunctionComponent<{}> = ({
  children,
}) => {
  const [authenticatedUser, setAuthenticatedUser] = useState<IUser>();
  const [viewedUser, setViewedUser] = useState<IUser>();
  const { updateSubscription } = useSubscriptionContext();

  // Recupera dados do usuário do sessionStorage
  useEffect(() => {
    const tokenJwt = sessionStorage.getItem(storageParams.tokenJwt);
    const nome = sessionStorage.getItem(storageParams.name);
    const tipo = sessionStorage.getItem(storageParams.userType) || undefined;
    const id = Number(sessionStorage.getItem(storageParams.userId) || "-1");
    if (tokenJwt && nome) {
      setAuthenticatedUser({
        nome,
        tipo,
        id,
        isAuthenticated: true,
      });
      setViewedUser({
        nome,
        tipo,
        id,
        isAuthenticated: true,
      });
      api.defaults.headers.Authorization = `Bearer ${tokenJwt}`;
    }
  }, []);

  useEffect(() => {
    if (authenticatedUser?.isAuthenticated && authenticatedUser?.id) {
      sUser.getUser(authenticatedUser.id).then((resp) => {
        setAuthenticatedUser((prevState) => ({
          ...prevState,
          nome: resp.data.nome,
          senhaInicial: resp.data.senhaInicial,
          tipo: resp.data.tipo,
        }));
        sessionStorage.setItem(storageParams.name, resp.data.nome);
        sessionStorage.setItem(storageParams.userType, resp.data.tipo);
        sessionStorage.setItem(storageParams.userId, resp.data.idUsuario);
      });
    }
  }, [authenticatedUser?.isAuthenticated, authenticatedUser?.id]);

  const handleLogin = useCallback(function (
    username: string,
    password: string,
    trustDevice: boolean
  ) {
    const tokenConfianca =
      localStorage.getItem(storageParams.tokenConfianca) || undefined;

    sAuthentication
      .postLogin(username, password, trustDevice, tokenConfianca)
      .then((response: any) => {
        const userData = response.data.data.usuario;

        // Caso tenha retornado token jwt no Header (MFA Desabilitado ou DISPOSITIVO CONFIÁVEL)
        if (response.data.data.autenticado) {
          setAuthenticatedUser({
            id: userData.idUsuario,
            nome: userData.nome,
            isAuthenticated: userData.isAuthenticated,
            senhaInicial: userData.senhaInicial,
            tipo: userData.tipo,
          });

          if (userData.tipo === userType.admin) {
            history.push("/user-select");
          } else {
            setViewedUser({
              id: userData.idUsuario,
              nome: userData.nome,
              isAuthenticated: userData.isAuthenticated,
              senhaInicial: userData.senhaInicial,
              tipo: userData.tipo,
            });

            sSubscription.getSubscription().then((resp) => {
              updateSubscription({
                idAssinatura: resp.data.idAssinatura,
                status: resp.data.status,
                dataInicial: resp.data.dataInicial,
                dataFinal: resp.data.dataFinal,
                periodoCarenciaPagamento: resp.data.periodoCarenciaPagamento,
                dataFinalCarenciaPosExpiracao:
                  resp.data.dataFinalCarenciaPosExpiracao,
              });
              if (resp.data.status !== "Ativa") {
                history.push("subscription");
                return;
              } else {
                  history.push("/channel-select");
              }
            });
          }
        } else {
          sessionStorage.setItem(storageParams.email, username);
          sessionStorage.setItem(
            storageParams.tokenStep2FA,
            response.data.data.tokenStep2FA
          );
          history.push("/mfa");
        }
      });
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [updateSubscription]);

  const handle2FA = useCallback(function (
    codigoSeguranca: string,
    token: string,
    email: string
  ) {
    sAuthentication
      .post2FA(codigoSeguranca, token, email)
      .then((response: any) => {
        if (response.headers["x-access-token"]) {
          if (response.data.data.tokenConfianca2FA) {
            localStorage.setItem(
              storageParams.tokenConfianca,
              response.data.data.tokenConfianca2FA
            );
          }

          // Remove TokenStep2FA
          sessionStorage.removeItem(storageParams.tokenStep2FA);

          const userData = response.data.data.usuario;
          setAuthenticatedUser({
            id: userData.idUsuario,
            nome: userData.nome,
            isAuthenticated: userData.isAuthenticated,
            senhaInicial: userData.senhaInicial,
            tipo: userData.tipo,
          });

          if (userData.tipo === userType.admin) {
            history.push("/user-select");
          } else {
            setViewedUser({
              id: userData.idUsuario,
              nome: userData.nome,
              isAuthenticated: userData.isAuthenticated,
              senhaInicial: userData.senhaInicial,
              tipo: userData.tipo,
            });
            sSubscription.getSubscription().then((resp) => {
              updateSubscription({
                idAssinatura: resp.data.idAssinatura,
                status: resp.data.status,
                dataInicial: resp.data.dataInicial,
                dataFinal: resp.data.dataFinal,
                periodoCarenciaPagamento: resp.data.periodoCarenciaPagamento,
                dataFinalCarenciaPosExpiracao:
                  resp.data.dataFinalCarenciaPosExpiracao,
              });
              if (resp.data.status !== "Ativa") {
                history.push("subscription");
                return;
              } else {
                  history.push("/channel-select");
              }
            });
          }
        }
      });
  },
  [updateSubscription]);

  const handleLogout = useCallback(function () {
    setViewedUser(undefined);
    setAuthenticatedUser(undefined);
    sessionStorage.clear();
    api.defaults.headers.Authorization = undefined;
    history.push("/");
  }, []);

  return (
    <UserContext.Provider
      value={{
        authenticatedUser,
        viewedUser,
        login: handleLogin,
        login2fa: handle2FA,
        logout: handleLogout,
        setAuthenticatedUser,
        setViewedUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
}

export default AuthenticationContextProvider;

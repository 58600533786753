import React from 'react';
import IPage from '../../interfaces/page';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import MainModal from '../../components/mainModal';
import TextInput from '../../components/textInput';
import { requiredValidator } from '../../utils/validators';
import { getter } from '@progress/kendo-react-common';
import sUser from '../../services/userService';
import { useUserContext } from '../../context/authentication';

const ChangePasswordPage: React.FunctionComponent<IPage> = function (props: any) {
    const { logout=()=>{} } = useUserContext();
    // Faz o login atualizando o contexto do usuário e fazendo os redirecionamentos necessários.
    const handleSubmit = async function (data: any) {
        if (await sUser.changePassword(data.password, data.newPassword, data.confirmNewPassword)) {
            logout();
        }
    }

    const validateNewPassword = function (value: any) {
        if (getter("newPassword")(value) === getter("confirmNewPassword")(value)) {
            return;
        }

        return {
            newPassword: "Senha e Confirmação de Senha não conferem",
            confirmNewPassword: "Senha e Confirmação de Senha não conferem"
        }
    }

    return (
        <div className="container-page padding-header">
            <MainModal title="Alterar Senha">
                <Form
                    onSubmit={handleSubmit}
                    validator={validateNewPassword}
                    render={(formProps) => (
                        <FormElement className="k-pt-6">
                            <div className="k-mb-2">
                                <Field
                                    name="password"
                                    type="password"
                                    placeholder="Senha Atual"
                                    validator={requiredValidator}
                                    icon="k-i-lock"
                                    component={TextInput}
                                />
                            </div>
                            <div className="k-mb-2">
                                <Field
                                    name="newPassword"
                                    type="password"
                                    placeholder="Nova Senha"
                                    validator={requiredValidator}
                                    icon="k-i-lock"
                                    component={TextInput}
                                />
                            </div>
                            <div>
                                <Field
                                    name="confirmNewPassword"
                                    type="password"
                                    placeholder="Confirmar Nova Senha"
                                    validator={requiredValidator}
                                    icon="k-i-lock"
                                    component={TextInput}
                                />
                            </div>
                            <div className="k-m-2 text-center">
                                <Button themeColor="primary" type="submit" disabled={!formProps.modified || !formProps.valid}>
                                    ENVIAR
                                </Button>
                            </div>
                        </FormElement>
                    )}
                />
            </MainModal>
        </div>
    )
}

export default ChangePasswordPage;
import React from 'react'
import { FieldRenderProps } from '@progress/kendo-react-form';
import { MaskedTextBox } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';

interface ITextInputProps extends FieldRenderProps{
    icon:string;
    placeholder:string;
    mask:string;
}

const TextInput = function (fieldRenderProps: ITextInputProps) {
    const { validationMessage, touched, icon, placeholder, mask, ...others } = fieldRenderProps;

    return (
        <div className="text-input-container">
            <span className="k-textbox k-space-left">
                <i className={'k-p-3 k-icon-input k-icon ' + icon}/>
                <MaskedTextBox {...others} placeholder={placeholder} mask={mask} includeLiterals={false} />
            </span>
            {validationMessage !== "" && touched && (
                <Error>{validationMessage}</Error>
            )}
        </div>
    )
}

export default TextInput;

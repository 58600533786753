import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { storageParams } from '../config/enum';
import { useUserContext } from '../context/authentication'
import { useChannelContext } from '../context/channel';
import { useTermsAcceptanceContext } from '../context/termsAcceptance';
import IPage from '../interfaces/page';
import ReactGa from 'react-ga';

interface RouteWrapperProps {
    component: React.FC<IPage>;
    isPrivate?: boolean;
    isAuthentication?: boolean;
    [rest: string]: any;
}

// Utilizado para gerenciar as rotas e redirecionamentos
const RouteWrapper: React.FC<RouteWrapperProps> = ({ component: Component, isPrivate=false, isAuthentication=false, title, ...rest }) => {
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            ReactGa.initialize('UA-40379330-6');
            ReactGa.pageview(rest.path);
        }
    }, [rest.path])

	const { authenticatedUser: user } = useUserContext();
    const { selectedChannel } = useChannelContext();
    const { empresaDocumentoAceite, logAceiteUsuario } = useTermsAcceptanceContext();

    const isAuthenticated = user?.isAuthenticated || !!sessionStorage.getItem(storageParams.tokenJwt);
    const needUpdatePassword = !!user?.senhaInicial;

    if (isAuthenticated) {
        // Se necessita atualizar senha, redireciona usuário
        if (needUpdatePassword && rest.path !== "/change-password" && rest.path !== "/") {
            return <Redirect to={{pathname: "/change-password"}} />;
        }

        
        // Verifica se existe algum termo pendente de aceitação
        const pendingTermsAcceptance = (
            selectedChannel != null &&
            (
                (
                    empresaDocumentoAceite
                        .filter(x => 
                            logAceiteUsuario?.dataAceite && 
                            new Date(x.data) > new Date(logAceiteUsuario.dataAceite)
                        ).length > 0
                ) ||
                (
                    !(logAceiteUsuario?.dataAceite) && 
                    empresaDocumentoAceite.length > 0
                )
            )
        );
        
        const allowPaths = (
            rest.path !== "/accept-terms" && 
            rest.path !== "/" &&
            rest.path !== '/channel-select' &&
            rest.path !== '/profile' &&
            rest.path !== '/change-password'
        );

        if (pendingTermsAcceptance && allowPaths) {
            return <Redirect to={{pathname: "/accept-terms"}} />
        }
    }

	// Caso o usuário não esteja logado e a rota é privada, redireciona para o login
	if(!isAuthenticated && isPrivate && rest.path !== "/") {
		return <Redirect to={{pathname: "/"}} />;
	}
    


  // Caso tudo esteja certo, redireciona para a página desejada
  return <Route {...rest} component={Component}/>;
}
export default RouteWrapper;
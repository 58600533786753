import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';

import ITermsAcceptanceContext from '../interfaces/termsAcceptanceContext';
import sEmpresaDocumentoAceite from '../services/empresaDocumentoAceiteService';
import sLogAceiteUsuario from '../services/logAceiteUsuarioService';

import IEmpresaDocumentoAceite from '../interfaces/empresaDocumentoAceite';
import ILogAceiteUsuario from '../interfaces/logAceiteUsuario';
import { useChannelContext } from './channel';


const TermsAcceptanceContext = createContext<ITermsAcceptanceContext | undefined>(undefined);

const TermsAcceptanceContextProvider: React.FunctionComponent<{}> = ({ children }) => {
    const { selectedChannel } = useChannelContext();
    const [empresaDocumentoAceite, setEmpresaDocumentoAceite] = useState<IEmpresaDocumentoAceite[]>([]);
    const [logAceiteUsuario, setLogAceiteUsuario] = useState<ILogAceiteUsuario | null>(null);

    const listEmpresaDocumentoAceite = useCallback(() => {
        sEmpresaDocumentoAceite.listDocumentosAceitos(selectedChannel?.idEmpresa)
            .then(resp => {
                setEmpresaDocumentoAceite(resp.data);
            });
    }, [selectedChannel]);

    const getLogAceiteUsuario = useCallback(async () => {
        sLogAceiteUsuario.getAceiteUsuario(selectedChannel?.idEmpresa)
            .then(resp => {
                setLogAceiteUsuario(resp.data);
            });
    }, [selectedChannel]);

    useEffect(() => {
        if (selectedChannel != null) {
            listEmpresaDocumentoAceite();
            getLogAceiteUsuario();
        } else {
            setEmpresaDocumentoAceite([]);
            setLogAceiteUsuario(null);
        }
    }, [getLogAceiteUsuario, listEmpresaDocumentoAceite, selectedChannel]);

    const downloadEmpresaDocumentoAceite = useCallback((idEmpresaDocumentoAceite: number, fileName: string) => {
        sEmpresaDocumentoAceite.getDocumento(idEmpresaDocumentoAceite)
            .then(resp => {
                var file = new File([resp.data], fileName);
                saveAs(file);
            });
    }, []);





    const insertLogAceiteUsuario = useCallback(() => {
        sLogAceiteUsuario.insertAceiteUsuario(selectedChannel?.idEmpresa)
            .then(resp => {
                getLogAceiteUsuario();
            });
    }, [getLogAceiteUsuario, selectedChannel?.idEmpresa]);


    const data: ITermsAcceptanceContext = {
        empresaDocumentoAceite,
        downloadEmpresaDocumentoAceite,
        logAceiteUsuario,
        insertLogAceiteUsuario
    }

    return (
        <TermsAcceptanceContext.Provider value={data}>
            {children}
        </TermsAcceptanceContext.Provider>
    );
}

export function useTermsAcceptanceContext() {
    const context = useContext(TermsAcceptanceContext);
    if (context === undefined) {
        throw new Error('useTermsAcceptanceContext must be used within a TermsAcceptanceContextProvider');
    }
    return context;
}

export default TermsAcceptanceContextProvider;
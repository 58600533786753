import api from '../api';
import IEndereco from '../interfaces/endereco';

const getEnderecosByUserId = async function (idUsuario: number) {
    return api.get(`/address`, { params: { usuarioId: idUsuario }});
}

const getCidades = async function (uf?:string) {
    return api.get(`/address/cities`, uf?{params:{uf: uf}}:{});
}

const getUFs = async function () {
    return api.get(`/address/ufs`);
}

const getCep = async function (cep: string) {
    return api.get(`/address/cep`, { params: { cep: cep }});
}

const updateEndereco = async function (endereco: IEndereco) {
    return api.put(`/address/${endereco.idEndereco}`, { endereco });
}

const insertEndereco  = async function (endereco: IEndereco) {
    return api.post(`/address`, { endereco });
}

const deleteEndereco  = async function ( idEndereco: number) {
    return api.delete(`/address/${idEndereco}`);
}

const enderecoService = {
    getCidades,
    getUFs,
    getEnderecosByUserId,
    getCep,
    updateEndereco,
    insertEndereco,
    deleteEndereco,
}

export default enderecoService;



import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import IChannel from "../interfaces/channel";
import IChannelContext from "../interfaces/channelContext";
import sUser from "../services/userService";
import { useUserContext } from "../context/authentication";
import { useSubscriptionContext } from "./subscription";
import { userType } from "../config/enum";

const ChannelContext = createContext<IChannelContext | undefined>(undefined);

const ReportContextProvider: React.FunctionComponent<{}> = ({ children }) => {
    const { viewedUser, authenticatedUser } = useUserContext();
    const { subscription } = useSubscriptionContext();
    const [channelOptions, setChannelOptions] = useState<IChannel[]>([]);
    const [selectedChannel, setSelectedChannel] = useState<IChannel>();

    const selectChannel = useCallback((channel?: IChannel) => {
        setSelectedChannel(channel);
    }, []);

    const clearChannel = useCallback(() => {
        setSelectedChannel(undefined);
        setChannelOptions([]);
    }, []);

    const loadChannels = useCallback((userId: number) => {
        sUser.listChannels(userId).then((resp) => {
            setChannelOptions(resp.data);
        });
    }, []);

    // Se o usuário a ser visualizado foi setado no contexto, lista os canais associados
    useEffect(() => {
        if (viewedUser?.id && (authenticatedUser?.tipo === userType.admin || subscription?.periodoCarenciaPagamento || subscription?.status?.toUpperCase() === "ATIVA")) {
            loadChannels(viewedUser.id);
        } else {
            clearChannel()
        }
    }, [loadChannels, viewedUser?.id, subscription, clearChannel, authenticatedUser?.tipo]);

    const data: IChannelContext = {
        loadChannels,
        channels: channelOptions,
        selectedChannel,
        selectChannel,
        clearChannel,
    };

    return (
        <ChannelContext.Provider value={data}>
            {children}
        </ChannelContext.Provider>
    );
};

export function useChannelContext() {
    const context = useContext(ChannelContext);
    if (context === undefined) {
        throw new Error(
            "useChannelContext must be used within a ChannelContextProvider"
        );
    }
    return context;
}

export default ReportContextProvider;

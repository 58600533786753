
import './styles.scss';

import React, { useEffect, useState } from 'react';
import IPage from '../../interfaces/page';
import MainModal from '../../components/mainModal';
import { Form, FormElement } from '@progress/kendo-react-form';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { useTermsAcceptanceContext } from '../../context/termsAcceptance';

import history from '../../routes/history';

const AboutPage: React.FunctionComponent<IPage> = function (props: any) {
    const { empresaDocumentoAceite, downloadEmpresaDocumentoAceite, logAceiteUsuario, insertLogAceiteUsuario } = useTermsAcceptanceContext();
    const [ countCheckboxChecked, setCountCheckboxChecked ] = useState(0);
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        // Ignora a primeira atualização do logAceiteUsuario
        if (firstRender) {
            setFirstRender(false);
            return;
        }

        history.push('/dashboard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logAceiteUsuario]);


    const handleCheckboxChange = (event:any) => {
        let currentCount = countCheckboxChecked;

        if (event.target.value) {
            currentCount++;
        } else {
            currentCount--;
        }

        setCountCheckboxChecked(currentCount);
    }


    const handleButtonSubmit = () => {
        if (countCheckboxChecked === empresaDocumentoAceite.length) {
            insertLogAceiteUsuario();
        }

        return false;
    }

    return (
        <div className="container-page padding-header">
            <MainModal title="Termos de Aceite">
                <div className="text-info">
                    <Form
                        render={() => {
                            return (
                                <FormElement>
                                    {
                                        empresaDocumentoAceite.map(doc => {
                                            let checkbox_children = undefined;

                                            if (doc.documento != null && doc.documento.length > 0) {
                                                const fileName = doc.nome.toLowerCase().trim().replace(/ /g, "_").normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                                                const fullFileName = `${fileName}.${doc.tipo.trim()}`;

                                                checkbox_children = (
                                                    <strong
                                                        className='cursor-pointer'
                                                        onClick={_ => downloadEmpresaDocumentoAceite(doc.idEmpresaDocumentoAceite, fullFileName)}
                                                    > <u>{doc.nome}</u>
                                                    </strong>
                                                );
                                            }

                                            return (
                                                <div className="text-primary caption k-mb-6" key={doc.idEmpresaDocumentoAceite}>
                                                    <Checkbox
                                                        label={doc.texto}
                                                        onChange={handleCheckboxChange}
                                                    >
                                                        {checkbox_children}
                                                    </Checkbox>

                                                </div>
                                            );
                                        })
                                    }

                                    <div className="text-center">
                                        <Button
                                            themeColor='primary'
                                            type="button"
                                            disabled={countCheckboxChecked !== empresaDocumentoAceite.length}
                                            onClick={handleButtonSubmit}
                                        >
                                            Aceitar
                                        </Button>
                                    </div>
                                </FormElement>
                            );
                        }}
                    />
                </div>
            </MainModal>
        </div>
    )
}

export default AboutPage;
import React from 'react';
import IPage from '../../interfaces/page';
import history from '../../routes/history';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import MainModal from '../../components/mainModal';
import TextInput from '../../components/textInput';
import { requiredValidator } from '../../utils/validators';
import sUser from '../../services/userService';
import PW from '../../assets/image/LogoPW.png'
import PCA from '../../assets/image/LogoPCA.png'

const RegisterPage: React.FunctionComponent<IPage> = function (props: any) {
    const handleSubmit = async function (data: any) {
        await sUser.createUser(data);
        history.push("/");
    }

    return (
        <div className="container-page">
            <img className="logoPW" alt="Logo Power Vision" src={PW}></img>
            <img className="logoPCA" alt="Logo PCA" src={PCA}></img>
            <MainModal title="Solicitação de Acesso">
                <div className="text-info">
                    Informe os dados abaixo para solicitar acesso aos relatórios
                </div>
                <Form
                    onSubmit={handleSubmit}
                    render={(formProps) => (
                        <FormElement>
                            <div className="k-mb-2">
                                <Field
                                    name="email"
                                    type="email"
                                    placeholder="E-mail"
                                    validator={requiredValidator}
                                    icon="k-i-email"
                                    component={TextInput}
                                />
                            </div>
                            <div className="text-center">
                                <div className="k-m-4">
                                    <div className="text-primary caption">
                                        <div className="link-button" onClick={() => history.push('/')}>
                                            Retornar ao login
                                        </div>
                                    </div>
                                </div>
                                <Button themeColor="primary" type="submit" disabled={!formProps.modified || !formProps.valid}>
                                    ENVIAR
                                </Button>
                            </div>
                        </FormElement>
                    )}
                />
            </MainModal>
        </div>
    )
}

export default RegisterPage;
import React from 'react'
import version from '../../utils/version';
import './styles.scss';

const Footer = function () {
    return (
        <div className="footer-container">
            @ 2022 PCA Engenharia de Software LTDA. Todos os direitos reservados. Versão {version.getVersion()}
        </div>
    )
}

export default Footer;

import { createContext, useContext, useState, useCallback } from 'react';
import INotificationContext from '../../interfaces/notificationContext';
import IMessage from '../../interfaces/message';
import Loading from '../../components/loading';
import { Slide } from "@progress/kendo-react-animation";
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import './styles.scss';

const NotificationContext = createContext<INotificationContext | undefined>(undefined);

const NotificationContextProvider: React.FunctionComponent<{}> = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<IMessage>();
    const [alertVisible, setAlertVisible] = useState(false);

    const showLoading = useCallback(function () {
        setLoading(true);
    }, [setLoading]);

    const hideLoading = useCallback(function () {
        setLoading(false);
    }, [setLoading]);

    const showMessage = useCallback(function (message: string, status: "success" | "info" | "warning" | "error") {
        setAlertVisible(true);
        setMessage({ message, status })

    }, [setAlertVisible, setMessage]);

    setTimeout(() => {
        setAlertVisible(false);
      }, 7000);

    const data: INotificationContext = {
        showLoading,
        hideLoading,
        showMessage
    }

    return (
        <NotificationContext.Provider value={data}>
            {children}
            <Loading show={loading} />
            <NotificationGroup className="notification-group">
                <Slide direction="up">
                    {message && alertVisible && (
                        <Notification
                            type={{ style: message.status, icon: false }}
                            closable={true}
                            onClose={() => setAlertVisible(false)}
                            className="notification"
                        >
                            <span>{message.message}</span>
                        </Notification>
                    )}
                </Slide>
            </NotificationGroup>
        </NotificationContext.Provider>
    );
}

export function useNotificationContext() {
    const context = useContext(NotificationContext);
    if (context === undefined) {
        throw new Error('useReportContext must be used within a NotificationContextProvider');
    }
    return context;
}

export default NotificationContextProvider;
import React from 'react';
import IPage from '../../interfaces/page';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import MainModal from '../../components/mainModal';
import TextInput from '../../components/textInput';
import { requiredValidator } from '../../utils/validators';
import { getter } from '@progress/kendo-react-common';
import { storageParams } from '../../config/enum';
import { useUserContext } from '../../context/authentication';
import history from '../../routes/history';
import PW from '../../assets/image/LogoPW.png'
import PCA from '../../assets/image/LogoPCA.png'

const MFAPage: React.FunctionComponent<IPage> = function (props: any) {
    const { login2fa } = useUserContext();

    // Faz o login atualizando o contexto do usuário e fazendo os redirecionamentos necessários.
    const handleSubmit = function (data: any) {
        const token = sessionStorage.getItem(storageParams.tokenStep2FA) || '';
        const email = sessionStorage.getItem(storageParams.email) || '';
        login2fa(data.codigo, token, email);
    }

    const validateNewPassword = function (value: any) {
        if (getter("newPassword")(value) === getter("confirmNewPassword")(value)) {
            return;
        }

        return {
            newPassword: "Senha e Confirmação de Senha não conferem",
            confirmNewPassword: "Senha e Confirmação de Senha não conferem"
        }
    }

    return (
        <div className="container-page">
            <img className="logoPW" alt="Logo Power Vision" src={PW}></img>
            <img className="logoPCA" alt="Logo PCA" src={PCA}></img>
            <MainModal title="Autenticação Múltiplos Fatores">
                <div className="text-info">
                    Informe o código de autenticação recebido via e-mail
                </div>
                <Form
                    onSubmit={handleSubmit}
                    validator={validateNewPassword}
                    render={(formProps) => (
                        <FormElement>
                            <div>
                                <Field
                                    name="codigo"
                                    placeholder="Código de Segurança"
                                    validator={requiredValidator}
                                    icon="k-i-lock"
                                    component={TextInput}
                                />
                            </div>
                            <div className="text-center">
                                <div className="k-m-4">
                                    <div className="text-primary caption">
                                        <div className="link-button" onClick={() => history.push('/')}>
                                            Retornar ao login
                                        </div>
                                    </div>
                                </div>
                                <Button themeColor="primary" type="submit" disabled={!formProps.modified || !formProps.valid}>
                                    ENVIAR
                                </Button>
                            </div>
                        </FormElement>
                    )}
                />
            </MainModal>
        </div>
    )
}

export default MFAPage;
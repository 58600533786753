const Message = {
    acessar: "Acessar",
    title: "Portal Power BI",
    defaultError: "Desculpe, ocorreu um erro inesperado. Tente novamente mais tarde.",
    defaultSuccess: "Operação realizada com sucesso.",
    maximoQuantidadePacotes: "Você já atingiu a quantidade máxima de produtos selecionados",
    dataReferencia: "Referência",
    salvar: "Salvar", 
    serverTimeout: "Desculpe, não foi possível estabelecer conexão com o servidor. Tente novamente mais tarde.",
    senha: "Senha",
    senhaAtual: "Senha atual",
    senhaNova: "Nova senha",
    senhaConfirmar: "Confirmar nova senha",
    solicitacaoLogin: "Faça login para continuar.",
    usuario: "Usuario",
    usuarioOffline: 'Por favor, verifique sua conexão com a internet antes de continuar.',
}

export default Message;
import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import './styles.scss';

interface IFormDialogProps {
    title:string;
    isVisible: boolean;
    closeModal: () => void;
    confirm: () => void;
}

const FormDialog: React.FunctionComponent<IFormDialogProps> = function ({ title, closeModal, isVisible, confirm, children }) {
    return (
        <div>
            {isVisible && (
                
                <Dialog closeIcon={false} className="dialog-container" width={350} title={title} onClose={closeModal}>
                    <div className="dialog-body">{children}</div>
                    <DialogActionsBar>
                        <Button onClick={closeModal} className="dialog-button">
                            Cancelar
                        </Button>
                        <Button themeColor="primary" onClick={confirm} className="dialog-button">
                            Confirmar
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}
        </div>
    );
};
export default FormDialog;
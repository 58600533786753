import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import MainModal from '../../components/mainModal';
import sEndereco from '../../services/enderecoService';
import { useUserContext } from '../../context/authentication';
import { FormEndereco } from './FormEndereco';
import { EnderecosView } from './EnderecosView';

import IPage from '../../interfaces/page';
import IEndereco from "../../interfaces/endereco";
import ICidade from '../../interfaces/cidade';
import IUF from '../../interfaces/uf';

const AddressesPage: React.FunctionComponent<IPage> = function () {
    const [enderecos, setEnderecos] = useState<Array<IEndereco>>();
    const [selected, setSelected] = useState<number | null>(null);
    const { authenticatedUser } = useUserContext();
    const [cidadeData, setCidadeData] = useState<Array<ICidade>>();
    const [ufData, setUfData] = useState<Array<IUF>>();

    useEffect(() => {
        let isSubscribed = true;
        const fetchCidades = async () => {
            const cidadesResponse = await sEndereco.getCidades();
            if (isSubscribed) {
                setCidadeData(cidadesResponse.data);
            }
        }

        const fetchUfs = async () => {
            const ufsResponse = await sEndereco.getUFs();
            if (isSubscribed) {
                setUfData(ufsResponse.data);
            }
        }

        fetchCidades().catch(console.error);
        fetchUfs().catch(console.error);

        return () => { isSubscribed = false }
    }, []);

    useEffect(() => {
        let isSubscribed = true;

        const fetchEnderecos = async () => {
            if (authenticatedUser?.id) {
                const enderecosResponse = await sEndereco.getEnderecosByUserId(authenticatedUser.id);
           
                const dataEnderecos = enderecosResponse.data;
                const dataEnderecoOrder = dataEnderecos.sort((a: { principal: boolean; }) => a.principal === true? -1 : 1);
                
                if (isSubscribed) {
                    setEnderecos(dataEnderecoOrder);
                    const enderecoPrincipal = dataEnderecoOrder.find((e:IEndereco) => e.principal);
                    if (enderecoPrincipal) {
                        setSelected(enderecoPrincipal.idEndereco);
                    }
                }
            }
        }
        fetchEnderecos().catch();
        return () => { isSubscribed = false }
    }, [authenticatedUser?.id])

    const setNovoEndereco = () => {
        setSelected(-1);

    }


    return (
        <div className="container-page padding-header">
            <MainModal title="Meus Endereços">
                <div className="k-pt-5 endereco-page">
                    <EnderecosView
                        enderecosData={enderecos ?? []}
                        cidadeData={cidadeData}
                        setSelected={setSelected}
                        selected={selected}
                      
                    />
                    <Button themeColor="primary" icon="plus-outline" className="endereco-view-addButton k-mt-4" onClick={setNovoEndereco}> NOVO ENDEREÇO </Button>
                    <FormEndereco
                        enderecosData={enderecos ?? []}
                        selected={selected}
                        cidadeData={cidadeData}
                        ufData={ufData}
                    />
                </div>
            </MainModal>
        </div>
    );
}

export default AddressesPage;


//import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {Dialog, DialogActions, DialogTitle} from "@mui/material";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { localDefaultDate } from "../../utils/date";
import { moneyFormat } from "../../utils/masks";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useUserContext } from "../../context/authentication";
import sSubscription from "../../services/subscriptionService";
import IPayment from "../../interfaces/payment";

interface IPaymentHistoryDialogProps {
    isVisible: boolean;
    paymentHistory: any[];
    nfseHistory: any[];
    closeDialog: () => void;
}

const PaymentHistoryDialog: React.FunctionComponent<IPaymentHistoryDialogProps> = ({isVisible, paymentHistory,nfseHistory, closeDialog}) => {
    const [filtroInicial, setFiltroInicial] = useState<Date| null>(null);
    const [filtroFinal, setFiltroFinal] = useState<Date| null>(null);
    const [payment, setPayment] = useState<IPayment[]>([]);
    const[state, setState] = useState<Boolean>(false);
    const { viewedUser } = useUserContext();
    

    const handleSubmitDate = async() => {
    
        setState(true);
    
        const paymentsResponse = await sSubscription.getPayments(
            viewedUser!.id, filtroInicial!, filtroFinal!
        );
        var pagamentos = paymentsResponse.data;        
        setPayment(pagamentos)
        construirTabelaPagamentos();
    }


    const construirTabelaPagamentos = () => {
        let historicoPagamentos;
        if(payment.length <= 0 || !state){
            historicoPagamentos = paymentHistory;
        }
        else{
            historicoPagamentos = payment;
        }
        
        let historicoNfse = nfseHistory;

        const rpsDownlod = (pagamentoId? : number) =>{
     
            if(historicoNfse.find(x => x.pagamentoId === pagamentoId)){
                var nfse = historicoNfse.find(x => x.pagamentoId === pagamentoId);
                if(nfse?.rpsurl !== undefined){
                    return(<a href={nfse?.rpsurl} className='history-subscription-payment-rps' rel="noreferrer" target="_blank"><span className='k-icon k-i-file-txt text-primary'></span></a>) 
                }
            }
            
        }
        const statusNfse = (pagamentoId? : number) =>{
          
            if(historicoNfse.find(x => x.pagamentoId === pagamentoId)){
               
                var nfse = historicoNfse.find(x => x.pagamentoId === pagamentoId);
                if(nfse?.status !== undefined){
                    return nfse.status; 
                }
            }
            
        }


        if (historicoPagamentos.length > 0) {
            return (
                <table cellSpacing={0}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Forma de Pagamento</th>
                            <th>Data do Pagamento</th>
                            <th>Valor</th>
                            <th>Status</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            historicoPagamentos.map((item, index) => {
                                return (
                                    
                                    <tr key={index}>
                                        <td className="text-center"> <span className="tooltipnfse" data-tooltipNfse="Baixar NFSe"> {rpsDownlod(item.pagamentoId)} </span> </td>
                                        <td> {item.formaPagamento} </td>
                                        <td> {localDefaultDate(item.dataPagamento)} </td>
                                        <td> {moneyFormat(item.valor, 'R$')} </td>
                                        <td>{statusNfse(item.pagamentoId)}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            );
        }

        return (
            <p className="text-center k-mt-8">Nenhum pagamento encontrado</p>
        );
    }


    return (
        <div>
            {isVisible && (

                <Dialog title={''} sx={{minWidth: 560}} open={true} onClose={closeDialog} className="paymenthistory-dialog">
                   <DialogTitle sx={{ m: 0, p: 2 }}>
                    Meus Pagamentos
                    </DialogTitle>
                    <div className="dialog-subsc">
                        <div className="paymenthistory-filter">
                 
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                           
                               value={filtroInicial}
                                label="Data Inicial"
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => <TextField {...params}  InputLabelProps={{ shrink: true }}  />}
                                onChange={(e) => {
                                    setFiltroInicial(e);
                                  }}
                                DialogProps={{
                                    PaperProps: { elevation: 13 }
                                  }}
                            />
              
                      <DatePicker
                           
                           value={filtroFinal}
                            label="Data Final"
                            inputFormat="dd/MM/yyyy"
                            renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
                            onChange={(e) => {
                                setFiltroFinal(e);
                              }}
                            DialogProps={{
                                PaperProps: { elevation: 13 }
                              }}
                        />
                              </LocalizationProvider>
                              <Button  className="btn-search-payment" onClick={handleSubmitDate}> Buscar </Button>
                        </div>

                        <div className="divider" />

                        <div className="paymenthistory-table">
                            {
                                construirTabelaPagamentos()
                            }
                        </div>
                    </div>

                    <DialogActions>
                        <Button className="btn-close-payment" onClick={closeDialog}>
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}

export default PaymentHistoryDialog;
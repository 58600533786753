import api from '../api';
import IRequestAccess from '../interfaces/requestAccess';
import IUsuarioBasic from '../interfaces/usuarioBasic';

const createUser = async function (user:IRequestAccess) {
    return api.post("/users/request-access", {
        email: user.email,
        cpf: user.cpf?.replace(/[^\d]/g, ''),
        cnpj: user.cnpj?.replace(/[^\d]/g, '')
    });
}

const updateUser = async function (usuario:IUsuarioBasic) {
    return api.put('/users', {
        nome: usuario.nome,
        cpf: usuario.cpf?.replace(/[^\d]/g, ''),
        cnpj: usuario.cnpj?.replace(/[^\d]/g, ''),
    });
}

const forgotPassoword = async function (email:string) {
    return api.post('/users/recover-password', { email });
}

const changePassword = async function (password: string, newPassword: string, confirmPassword: string) {
    return api.put('/users/password', { senha: password, novaSenha: newPassword, confirmacaoNovaSenha: confirmPassword });
}

const getUser = async function (idUsuario: number) {
    return api.get(`/users/${idUsuario}`);
}

const listUsers = async function () {
    return api.get('/users', { params: { tipo: 'UsuarioApp', ativo: true }});
}

const listChannels = async function (idUsuario?: number) {
    return  api.get(`/users/${idUsuario}/channels`, { params: { ativo: true }, headers: { 'x-loading-screen': false }});
}

const listWorkspacesFromChannel = async function (idUsuario: number, idCanal: number) {
    return  api.get(`/users/${idUsuario}/channels/${idCanal}/workspaces`);
}

const listReportsFromWorkspace = async function (idUsuario: number, idCanal: number, idWorkspace: number) {
    return  api.get(`/users/${idUsuario}/channels/${idCanal}/workspaces/${idWorkspace}/reports`);
}

const userList = async function (email?: string, nome?: string) {
    return api.get('/users', { params: { email: email, nome: nome, tipo: 'UsuarioApp', ativo: true }});
}

const userService = {
    createUser,
    updateUser,
    forgotPassoword,
    changePassword,
    getUser,
    listUsers,
    listChannels,
    listWorkspacesFromChannel,
    listReportsFromWorkspace,
    userList
}

export default userService;



import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import IWorkspaceContext from "../interfaces/workspaceContext";
import IWorkspace from "../interfaces/workspace";
import IEmbedPowerBIObject from "../interfaces/embedPowerBIObject";
import sUser from "../services/userService";
import IReportsDashboards from "../interfaces/reportsDashboards";
import { useChannelContext } from "./channel";
import { useUserContext } from "./authentication";

const ReportContext = createContext<IWorkspaceContext | undefined>(undefined);

const ReportContextProvider: React.FunctionComponent<{}> = ({ children }) => {
    const { selectedChannel } = useChannelContext();
    const { viewedUser } = useUserContext();

    const [workspaceOptions, setWorkspaceOptions] = useState<IWorkspace[]>([]);
    const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspace>();
    const [embedPowerBIObjectOptions, setEmbedPowerBIObjectOptions] = useState<
        IEmbedPowerBIObject[]
    >([]);
    const [selectedEmbedPowerBIObject, setSelectedEmbedPowerBIObject] =
        useState<IEmbedPowerBIObject>();

    const selectWorkspace = useCallback((workspace: IWorkspace) => {
        setSelectedWorkspace(workspace);
    }, []);

    const selectEmbedPowerBIObject = useCallback(
        (report: IEmbedPowerBIObject) => {
            setSelectedEmbedPowerBIObject(report);
        },
        []
    );

    const clearReports = useCallback(() => {
        setSelectedEmbedPowerBIObject(undefined);
        setEmbedPowerBIObjectOptions([]);
    }, []);

    const clearWorkspaces = useCallback(() => {
        clearReports();
        setSelectedWorkspace(undefined);
        setWorkspaceOptions([]);
    }, [clearReports]);

    const loadChannelWorkspaces = useCallback(
        async (userId: number, channelId: number) => {
            sUser.listWorkspacesFromChannel(userId, channelId).then((resp) => {
                let workspacesOption = resp.data.map((workspace: any) => ({
                    id: workspace.idWorkspace,
                    name: workspace.nome,
                    idPowerBi: workspace.powerBiWorkspaceId,
                }));
                const ids = workspacesOption.map((o: { id: any; }) => o.id)
                const workspacesOptionFilter = workspacesOption.filter(({id}: any, index: number) => {
                    return !ids.includes(id, index + 1);
                })
                setWorkspaceOptions(workspacesOptionFilter);
                selectWorkspace(workspacesOptionFilter[0]);
            });
        },
        [selectWorkspace]
    );

    const fetchReports = async function (
        userId: number,
        channelId: number,
        workspaceId: number
    ) {
        const resp = await sUser.listReportsFromWorkspace(
            userId,
            channelId,
            workspaceId
        );
        const { reports, dashboards } = resp.data as IReportsDashboards;
        const reportOptions = reports?.map((report, index) => ({
            id: report.id,
            name: report.name,
            embedUrl: report.embedUrl,
            accessToken: report.embedToken.token,
            type: "report",
        }));
        const dashboardOptions = dashboards?.map((dashboard, index) => ({
            id: dashboard.id,
            name: dashboard.name,
            embedUrl: dashboard.embedUrl,
            accessToken: dashboard.embedToken.token,
            type: "dashboard",
        }));

        return [...(reportOptions || []), ...(dashboardOptions || [])];
    };

    const loadWorkspacesReports = useCallback(
        async (userId: number, channelId: number, workspaceId: number) => {
            const options = await fetchReports(userId, channelId, workspaceId);
            setEmbedPowerBIObjectOptions(options);
            selectEmbedPowerBIObject(options[0]);
        },
        [selectEmbedPowerBIObject]
    );

    const refreshTokens = useCallback(
        async function (
            userId: number,
            channelId: number,
            workspaceId: number
        ) {
            const options = await fetchReports(userId, channelId, workspaceId);
            setEmbedPowerBIObjectOptions(options);
            setSelectedEmbedPowerBIObject((prevState) =>
                options.find((x) => x.id === prevState?.id)
            );
            selectEmbedPowerBIObject(options[0]);
        },
        [selectEmbedPowerBIObject]
    );

    // Limpa reports e workspaces caso canal não esteja selecionado
    useEffect(() => {
        if (!selectedChannel) {
            clearReports();
            clearWorkspaces();
        }
    }, [clearReports, clearWorkspaces, selectedChannel]);

    // Carrega relatórios/dashboards/tiles do Workspace sempre que ocorre alteração do workspace selecionado
    useEffect(() => {
        if (selectedWorkspace && viewedUser?.id && selectedChannel?.idCanal) {
            loadWorkspacesReports(
                viewedUser.id,
                selectedChannel.idCanal,
                selectedWorkspace.id
            );
        }
    }, [
        loadWorkspacesReports,
        selectedWorkspace,
        viewedUser?.id,
        selectedChannel?.idCanal,
    ]);

    // Carrega workspaces do canal sempre que ocorre alteração do canal selecionado
    useEffect(() => {
        if (selectedChannel && viewedUser?.id) {
            loadChannelWorkspaces(viewedUser.id, selectedChannel.idCanal);
        }
    }, [loadChannelWorkspaces, selectedChannel, viewedUser?.id]);

    const data: IWorkspaceContext = {
        selectWorkspace,
        selectedWorkspace,
        clearWorkspaces,
        workspaceOptions,
        loadChannelWorkspaces,

        selectedEmbedPowerBIObject,
        selectEmbedPowerBIObject,
        embedPowerBIObjectOptions,
        clearEmbedPowerBIObjects: clearReports,
        refreshTokens,
    };

    return (
        <ReportContext.Provider value={data}>{children}</ReportContext.Provider>
    );
};

export function useReportContext() {
    const context = useContext(ReportContext);
    if (context === undefined) {
        throw new Error(
            "useReportContext must be used within a ReportContextProvider"
        );
    }
    return context;
}

export default ReportContextProvider;


import api from '../api';

const acceptInvitation = async function (idConvite: number, codigoSeguranca: string) {
    return api.post(`/invitation/${idConvite}/accept`, {
        codigoSeguranca,
    });
}

const refuseInvitation = async function (idConvite: number, codigoSeguranca: string, motivoRecusa: string) {
    return api.post(`/invitation/${idConvite}/refuse`, {
        codigoSeguranca,
        motivoRecusa
    });
}

const invitationService = {
    acceptInvitation,
    refuseInvitation
}

export default invitationService;

import api from '../api';

const listDocumentosAceitos = async function (idEmpresa?: number) {
    return api.get(`/empresaDocumentoAceite?IdEmpresa=${idEmpresa}`);
}

const getDocumento = async function (idEmpresaDocumentoAceite: number) {
    return api.get(`/empresaDocumentoAceite/download?IdEmpresaDocumentoAceite=${idEmpresaDocumentoAceite}`, {
        responseType: 'blob',
    });
}

const empresaDocumentoAceiteService = {
    listDocumentosAceitos,
    getDocumento
}

export default empresaDocumentoAceiteService;

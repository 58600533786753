import './styles.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import sEndereco from '../../services/enderecoService';
import TextInput from '../../components/textInput';
import Combobox from '../../components/combobox';
import history from '../../routes/history';
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Checkbox } from '@progress/kendo-react-inputs';
import { requiredValidator } from '../../utils/validators';
import IEndereco from '../../interfaces/endereco';
import ICidade from '../../interfaces/cidade';
import IUF from '../../interfaces/uf';
import ICep from '../../interfaces/cep';

interface ICamposFormEnderecoProps {
    enderecosData: Array<IEndereco>;
    cidadeData?: Array<ICidade>;
    ufData?: Array<IUF>;
    selected?: number | null;
    setRuaSelected?: Array<ICep>;


}

interface CustomFormRenderProps extends FormRenderProps {
    formProps: FormRenderProps,
    cidadeOptions: Array<ICidade>,
    cidadeSelected: ICidade | undefined | null,
    ufOptions: Array<IUF>,
    ufData?: Array<IUF>,
    ufSelected: IUF | undefined,
    ruaSelected: ICep | undefined | null,
    setRuaSelected: React.Dispatch<React.SetStateAction<ICep | undefined | null>>,
    setUFSelected: React.Dispatch<React.SetStateAction<IUF | undefined>>,
    setCidadeSelected: React.Dispatch<React.SetStateAction<ICidade | undefined | null>>,
}

const CustomFormRender = (props: CustomFormRenderProps) => {
    const { cidadeOptions, cidadeSelected, setCidadeSelected, ufOptions, ufSelected, ruaSelected, setRuaSelected, setUFSelected, ufData, formProps } = props;

    const handlerCidade = (e: any) => {
        setCidadeSelected(e?.value);
    }

    const handlerUF = async (e: any) => {
        setUFSelected(e?.value);
        setCidadeSelected(null);
    }
    const returnHistory = (e: any) => {
        e.preventDefault();
        history.push('/profile');
    }
    useEffect(() => {
        if (ruaSelected !== null && ruaSelected !== undefined && ruaSelected?.idCidade !== undefined) {
            formProps.onChange("logradouro", {
                value: ruaSelected?.logradouro,

            });
            formProps.onChange("bairro", {
                value: ruaSelected?.bairroS,
            });

            setUFSelected(ufData?.find(u => u.idUf === ruaSelected?.idUf));
            setCidadeSelected(cidadeOptions.find(e => e.nome === ruaSelected?.cidadeS));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ruaSelected, cidadeOptions]);
    // Deixar apenas ruaSelected e cidadeOptions como dependencia 

    
    const handlerCep = useCallback(async (dados: any) => {

        const inputNumero = document.getElementById('002') as HTMLInputElement | null;
        const inputLogradouro = document.getElementById('003') as HTMLInputElement | null;
        let cep = dados?.value?.replace(/[^0-9]/g, '');
        formProps.onChange("cep", {
            value: cep,

        });
        if (cep.length === 8) {
            const { status, data } = await sEndereco.getCep(cep);
            if (status !== 200) throw new Error()
            setRuaSelected(data)
            if(data.logradouro !== undefined){
                inputNumero?.focus();
            }else{
                inputLogradouro?.focus()
            }
        } else {
            return;
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setRuaSelected])

    return (
        <FormElement>

            <GridLayout
                gap={{ rows: 10, cols: 4 }}
                cols={[{ width: 130 }, { width: 110 }, { width: 110 }, { width: 120 }]}
            >
                <GridLayoutItem row={1} col={1} colSpan={1}>
                    <Field

                        maxLength={8}
                        id="001"
                        name="cep"
                        placeholder="CEP"
                        validator={requiredValidator}
                        icon="k-i-parameters-unknown"
                        component={TextInput}
                        onChange={handlerCep}
                    />

                </GridLayoutItem>
                <GridLayoutItem row={1} col={2} colSpan={1}>
                    <div className='tooltip' data-tooltip="Digite apenas os números do CEP"> <i className={'k-icon k-i-info'} /></div>
                </GridLayoutItem>
                <GridLayoutItem row={2} colSpan={4}>
                    <Field
                        id='003'
                        name="logradouro"
                        placeholder="Logradouro"
                        validator={requiredValidator}
                        icon="k-i-parameters-unknown"
                        component={TextInput}
                        textField="logradouro"
                    />
                </GridLayoutItem>
                <GridLayoutItem row={3} col={1} colSpan={2}>
                    <Field
                        id="002"
                        name="numero"
                        placeholder="Número"
                        validator={requiredValidator}
                        icon="k-i-parameters-unknown"
                        component={TextInput}
                    />
                </GridLayoutItem>
                <GridLayoutItem row={3} col={3} colSpan={2}>
                    <Field
                        name="complemento"
                        placeholder="Complemento"
                        icon="k-i-parameters-unknown"
                        component={TextInput}
                    />
                </GridLayoutItem>
                <GridLayoutItem row={4} colSpan={2}>
                    <Field
                        name="bairro"
                        placeholder="Bairro"
                        validator={requiredValidator}
                        icon="k-i-parameters-unknown"
                        component={TextInput}
                    />
                </GridLayoutItem>
                <GridLayoutItem row={5} col={1} colSpan={1}>
                    <Field
                        name="uf"
                        placeholder="UF"
                        validFunc={requiredValidator}
                        icon="k-i-parameters-unknown"
                        component={Combobox}
                        data={ufOptions}
                        textField="idUf"
                        defaultValue={ufSelected}
                        onChange={handlerUF}
                    />
                </GridLayoutItem>
                <GridLayoutItem row={5} col={2} colSpan={3}>
                    <Field
                        name="cidade"
                        placeholder="Município"
                        validFunc={requiredValidator}
                        icon="k-i-parameters-unknown"
                        component={Combobox}
                        data={cidadeOptions}
                        style={{ width: `${130 + 110 + 110}px` }}
                        textField="nome"
                        defaultValue={cidadeSelected}
                        onChange={handlerCidade}
                    />
                </GridLayoutItem>

                <GridLayoutItem row={6} colSpan={2}>
                    <Field
                        name="principal"
                        label="É o endereço principal?"
                        component={Checkbox}

                    />
                </GridLayoutItem>
            </GridLayout>
            <div className="text-center k-mt-2">
                <div className="button-positions">
                    <div>
                        <Button themeColor='primary' type="submit" disabled={!formProps.valid}>
                            SALVAR
                        </Button>
                    </div>
                    <div>
                        <Button fillMode="outline" onClick={(event) => { returnHistory(event);}}>
                            MEUS DADOS
                        </Button>
                    </div>

                </div>
            </div>
        </FormElement>
    );
}

export const FormEndereco: React.FunctionComponent<ICamposFormEnderecoProps> = function ({ enderecosData, cidadeData, ufData, selected }) {

    const defaultEndereco: IEndereco = { idEndereco: 0 };
    const enderecoData = enderecosData.find(e => e.idEndereco === selected) ?? defaultEndereco;
    const [cidadeOptions, setCidadeOptions] = useState<Array<ICidade>>([]);
    const [cidadeSelected, setCidadeSelected] = useState<ICidade | undefined | null>(cidadeData?.find(c => c.idcidade === enderecoData?.idCidade));
    const [ufOptions, setUfOptions] = useState<Array<IUF>>([]);
    const [ufSelected, setUFSelected] = useState<IUF | undefined>(ufData?.find(u => u.idUf === enderecoData?.idUf));
    const [ruaSelected, setRuaOSelected] = useState<ICep | undefined | null>();


    useEffect(() => {
        if (ufData && ufData?.length > 0) {
            setUfOptions(ufData);
        }

        return () => { };
    }, [ufData]);

    useEffect(() => {

        if (cidadeData && cidadeData?.length > 0) {
            let cidadeFiltro: Array<ICidade> = cidadeData;
            cidadeFiltro = cidadeData.filter(c => c.idUf?.toUpperCase() === ufSelected?.idUf?.toUpperCase());
            setCidadeOptions(cidadeFiltro);
        }

    }, [cidadeData, ufSelected])



    useEffect(() => {
        if (cidadeData && cidadeData?.length > 0 && selected !== -1) {
            let cidadeFiltro: Array<ICidade> = cidadeData;
            if (enderecoData?.idUf) {

                setUFSelected(ufData?.find(u => u.idUf === enderecoData.idUf));
                cidadeFiltro = cidadeData.filter(c => c.idUf?.toUpperCase() === enderecoData.idUf?.toUpperCase());
            }
            if (enderecoData?.idCidade) {
                setCidadeSelected(cidadeData?.find(c => c.idcidade === enderecoData.idCidade));
            }
            setCidadeOptions(cidadeFiltro);
        } else {
            setCidadeSelected(null);
            setUFSelected(undefined);
        }

        return () => { };
    }, [enderecoData.idUf, cidadeData, selected, enderecoData.idCidade, ufData])

    const handleSubmit = async function (data: any) {
        const existsPrincipal = enderecosData.find(e => e.principal === true);

         if(!existsPrincipal || existsPrincipal?.idUsuario === data.idUsuario){
             data.principal = true;
         }

        const enderecoDataPayload = { ...data, idCidade: cidadeSelected?.idcidade, idUf: ufSelected?.idUf };
        delete enderecoDataPayload.cidade;
        delete enderecoDataPayload.uf;
        if (data && data.idEndereco && data.idEndereco !== 0) {
            await sEndereco.updateEndereco(enderecoDataPayload);
            history.push('/addresses');
        } else {
            await sEndereco.insertEndereco(enderecoDataPayload);
            history.push('/addresses');
        }
    }

    if (!selected) {
        return null;
    }


    return (
        <>
            <hr className="divider k-mt-4" />
            <div className="text-info">
                Preencha as informações abaixo com os dados de endereço. O endereço selecionado será utilizado para a emissão da Nota Fiscal de Serviço.
            </div>
            <Form
                onSubmit={handleSubmit}
                initialValues={enderecoData}
                key={JSON.stringify(enderecoData)}

                render={(formProps) => {
                    return (

                        <CustomFormRender

                            ruaSelected={ruaSelected}
                            ufData={ufData}
                            cidadeOptions={cidadeOptions}
                            cidadeSelected={cidadeSelected}
                            ufOptions={ufOptions}
                            ufSelected={ufSelected}
                            setRuaSelected={setRuaOSelected}
                            setUFSelected={setUFSelected}
                            setCidadeSelected={setCidadeSelected}
                            formProps={{ ...formProps }}
                            {...formProps} />
                    )
                }
                }
            />
        </>
    );
}

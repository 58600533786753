import {
    createContext,
    useCallback,
    useContext,
    useState,
} from "react";
import ISubscription from "../interfaces/subscription";
import ISubscriptionContext from "../interfaces/subscriptionContext";

const SubscriptionContext = createContext<ISubscriptionContext | undefined>(undefined);

const SubscriptionContextProvider: React.FunctionComponent<{}> = ({ children }) => {
    const [subscription, setSubscription] = useState<ISubscription>()

    const updateSubscription = useCallback(function (updatedSubscription?: ISubscription) {
        setSubscription(updatedSubscription);
    }, [])

    return (
        <SubscriptionContext.Provider value={{ updateSubscription, subscription }}>
            {children}
        </SubscriptionContext.Provider>
    );
};

export function useSubscriptionContext() {
    const context = useContext(SubscriptionContext);
    if (context === undefined) {
        throw new Error(
            "useSubscriptionContext must be used within a SubscriptionContextProvider"
        );
    }
    return context;
}

export default SubscriptionContextProvider;

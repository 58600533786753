export function dataPorExtenso(data: Date) {
    var dia = data.getDate() < 10 ? `0${data.getDate()}` : `${data.getDate()}`;
    var mes = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][data.getMonth()];
    var ano = data.getFullYear();

    return `${dia} de ${mes} de ${ano}`
}


export function localDefaultDate(data: Date | undefined) {
    if (!data) {
        return '-';
    }

    return new Date(data).toLocaleDateString('pt-BR');
}

export function localDefaultTime(hora: Date | undefined) {
    if (!hora) {
        return '-';
    }

    return new Date(hora).toLocaleTimeString('pt-BR');
}

export function isValidDate(d: any) {
    return d instanceof Date && !isNaN(d as any);
}
import React from "react";
import ReactDOM from "react-dom";
import "./styles/global.scss";
import "./styles/kendoTheme.scss";
import App from "./App";
import AuthenticationContextProvider from "./context/authentication";
import NotificationContextProvider from "./context/notification";
import ReportContextProvider from "./context/workspace";
import ChannelContextProvider from "./context/channel";
import TermsAcceptanceContextProvider from "./context/termsAcceptance";
import AxiosInterceptor from "./api/interceptor";
import SubscriptionContextProvider from "./context/subscription";

ReactDOM.render(
  <React.StrictMode>
    <NotificationContextProvider>
      <SubscriptionContextProvider>
        <AuthenticationContextProvider>
          <AxiosInterceptor>
            <ChannelContextProvider>
              <ReportContextProvider>
                <TermsAcceptanceContextProvider>
                  <App />
                </TermsAcceptanceContextProvider>
              </ReportContextProvider>
            </ChannelContextProvider>
          </AxiosInterceptor>
        </AuthenticationContextProvider>
      </SubscriptionContextProvider>
    </NotificationContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

import './styles.scss';

import React, { useEffect, useState } from 'react';
import { EnderecoCard } from './EnderecoCard';

import IEndereco from "../../interfaces/endereco";
import ICidade from '../../interfaces/cidade';

interface IEnderecosViewProps {
    enderecosData:Array<IEndereco>;
    setSelected:React.Dispatch<React.SetStateAction<number | null>>;
    cidadeData?:Array<ICidade>;
    selected:number | null;
}

export interface IEnderecoViewProps {
    idEndereco: number,
    logradouro: string,
    numero: string,
    nomeCidade: string,
    uf: string,
    cep: string,
    principal?: boolean,
}

export const EnderecosView: React.FunctionComponent<IEnderecosViewProps> = function ({ enderecosData, cidadeData, setSelected, selected }) {

    const [enderecosView, setEnderecosView] = useState<Array<IEnderecoViewProps>>([]);

    useEffect(() => {

        if (cidadeData?.length) {
            const mapping = enderecosData.map(e => ({
                idEndereco: e.idEndereco,
                logradouro: e.logradouro ?? '',
                numero: e.numero ?? '',
                cep: e.cep ?? '',
                principal: e.principal,
                nomeCidade: cidadeData?.find(c => c.idcidade === e.idCidade)?.nome ?? '',
                uf: e.idUf ?? ''
            }));
            setEnderecosView(mapping);
        }

    }, [cidadeData, enderecosData]);

    if (enderecosView.length === 0) {
        return null;
    }


    return (
        <div className="endereco-view">
            {enderecosView.map((e, idx) => {
                return <EnderecoCard
                    liKey={idx}
                    setSelected={setSelected}
                    selected={selected}
                    endereco={e}
                />
            })}
        </div>
    );
}

const enumTranslateDictionary = {
    SaldoCarteira: 'Saldo carteira',
    Boleto: 'Boleto bancário',
    CartaoCredito: 'Cartão de crédito',
    CartaoDebito: 'Cartão de débito',
    Outros: 'Outros'
};

function translateEnum(enumValue: string) {
    return (enumTranslateDictionary as any)[enumValue] || enumValue;
}

export { translateEnum };

import api from '../api';

const getSubscription = async function () {
    return api.get('/subscription', { headers: { 'x-loading-screen': false } });
}

const renewSubscription = async function () {
    return api.post('/subscription/renew')
}
const getPayments = async function (usuarioId: number | undefined, dataStart: Date | undefined, dataFim: Date | undefined) {
    let dateStart = dataStart?.toISOString().split('T')[0];
    let dateFim = dataFim?.toISOString().split('T')[0];
    return api.get('/subscription/list', { params: { usuarioId: usuarioId, dateStart: dateStart, dateFim: dateFim } });
}

const subscriptionService = {
    getSubscription,
    renewSubscription,
    getPayments
}


export default subscriptionService;

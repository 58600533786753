import api from '../api';

const postLogin = async function (username:string, password:string, trustDevice:boolean, tokenConfianca?:string) {
    return api.post("/users/authenticate", {
        tokenConfianca,
        confiarDispositivo: trustDevice,
        email: username,
        credentials: btoa(`${username}:${password}`)
    });
}

const post2FA = async function (codigoSeguranca:string, token:string, email:string) {
    return api.post("/users/authenticate/2fa", { codigoSeguranca, token, email });
}

const authenticationService = {
    postLogin,
    post2FA
}

export default authenticationService;

import './styles.scss';

import React, { useState } from 'react';
import { IEnderecoViewProps } from './EnderecosView';
import { Button } from '@progress/kendo-react-buttons';
import FormDialog from '../../components/formDialog';
import sEndereco from '../../services/enderecoService';
import history from '../../routes/history';

interface IEnderecoCardProps {
    liKey:number,
    endereco:IEnderecoViewProps,
    setSelected:React.Dispatch<React.SetStateAction<number | null>>,
    selected:number | null,
}

export const EnderecoCard: React.FunctionComponent<IEnderecoCardProps> = function ({ liKey, endereco, setSelected, selected }) {
    const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false);
    const openAcceptModal = function () {
        setShowAcceptModal(true);
    }
    const closeAcceptModal = function () {
        setShowAcceptModal(false);
    }

    const deleteEndereco = async function () {
        closeAcceptModal();
        await sEndereco.deleteEndereco(endereco.idEndereco);
        history.push('/profile');

    }
    return (
        <li key={liKey} className={selected === endereco.idEndereco ? "endereco-view-selected" : ""}>
            <div className='endereco-view-line'>
                <div className='endereco-view-nomes'>
                    {endereco.logradouro}
                </div>
                <div>
                    {' , '}{endereco.numero}
                </div>
            </div>
            <div className='endereco-view-line'>
                <div className='endereco-view-cidade'>
                    {endereco.nomeCidade}
                </div>
                <div className='endereco-view-uf'>
                    {` - ${endereco.uf}`}
                </div>
            </div>
            <div className='endereco-view-line'>
                <div>
                    {endereco.cep}
                </div>
            </div>
            <div className='endereco-view-actions'>
                <Button className="endereco-view-actionButton"
                    icon={endereco.principal ? "star" : "pencil" }
                    onClick={() => { setSelected(endereco.idEndereco) }}
                />
                  <Button className="endereco-view-actionButton"
                    icon={"trash"}
                    onClick={openAcceptModal}
                />

            </div>
            <FormDialog isVisible={showAcceptModal} closeModal={closeAcceptModal} confirm={deleteEndereco} title="Confirmação de Exclusão">
                Tem certeza que deseja excluir esse endereço?
            </FormDialog>
        </li>
    );
}

import api from '../api';

const getConfigMercadoPago = async function () {
    return api.get('/parametrosistema/mercadopago');
}

const parametroSistemaService = {
    getConfigMercadoPago
}

export default parametroSistemaService;

import './styles.scss';

import React, { useEffect, useState } from 'react';
import IPage from '../../interfaces/page';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import MainModal from '../../components/mainModal';
import TextInput from '../../components/textInput';
import sUser from '../../services/userService';
import history from '../../routes/history';
import MaskedInput from '../../components/maskedInput';
import { cnpjValidator, requiredValidator } from '../../utils/validators';
import IUsuarioProfile from '../../interfaces/usuarioProfile';
import { cnpj } from '../../utils/masks'
import { useUserContext } from '../../context/authentication';
import IEndereco from '../../interfaces/endereco';
import sEndereco from '../../services/enderecoService';
import sSubscription from "../../services/subscriptionService";
import { useSubscriptionContext } from '../../context/subscription';

const ProfilePage: React.FunctionComponent<IPage> = function () {
    const [usuario, setUsuario] = useState<IUsuarioProfile>();
    const { authenticatedUser } = useUserContext();
    const [enderecos, setEnderecos] = useState<Array<IEndereco>>();
    const [selected, setSelected] = useState<number | null>(null);
    const { viewedUser } = useUserContext();
    const { subscription, updateSubscription } = useSubscriptionContext();

    useEffect(() => {
        if (viewedUser) {
          sSubscription.getSubscription().then((resp) => {
            updateSubscription({
              idAssinatura: resp.data.idAssinatura,
              status: resp.data.status,
              dataInicial: resp.data.dataInicial,
              dataFinal: resp.data.dataFinal,
              periodoCarenciaPagamento: resp.data.periodoCarenciaPagamento,
              dataFinalCarenciaPosExpiracao: resp.data.dataFinalCarenciaPosExpiracao
            });
          });
        }
      }, [updateSubscription, viewedUser]);
    
    useEffect(() => {
        let isSubscribed = true;

        const fetchEnderecos = async () => {
            if (authenticatedUser?.id) {
                const enderecosResponse = await sEndereco.getEnderecosByUserId(authenticatedUser.id);
                const dataEnderecos = enderecosResponse.data;
                if (isSubscribed) {
                    setEnderecos(dataEnderecos);
                    const enderecoPrincipal = dataEnderecos.find((e:IEndereco) => e.principal);
                    if (enderecoPrincipal) {
                        setSelected(enderecoPrincipal.idEndereco);
                    }
                }
            }
        }
        fetchEnderecos().catch();
        return () => { isSubscribed = false }
    }, [authenticatedUser?.id])

    useEffect(() => {
        if (authenticatedUser?.id) {
            sUser.getUser(authenticatedUser.id).then(resp => {
                const dataUsuario = resp.data;
                setUsuario({
                    email: dataUsuario.email,
                    name: dataUsuario.nome,
                    cnpj: cnpj(dataUsuario.cnpj),
                });
            });
        }
    }, [authenticatedUser?.id])

    // Faz o login atualizando o contexto do usuário e fazendo os redirecionamentos necessários.
    const handleSubmit = async function (data: any) {
        await sUser.updateUser({ nome: data.name, cnpj: data.cnpj, cpf: data.cpf });
    }

    return (
        <div className="container-page padding-header">
            <MainModal title="Meus Dados">
                <div className="text-info">
                    Preencha o formulário abaixo para atualizar seu cadastro.
                </div>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={usuario}
                    key={JSON.stringify(usuario)}
                    render={(formProps) => (
                        <FormElement>
                            <div className="k-mb-2">
                                <Field
                                    name="email"
                                    type="email"
                                    disabled
                                    placeholder="E-mail"
                                    icon="k-i-email"
                                    component={TextInput}
                                />
                            </div>
                            <div className="k-mb-2">
                                <Field
                                    name="name"
                                    placeholder="Razão social"
                                    validator={requiredValidator}
                                    icon="k-i-parameters-unknown"
                                    component={TextInput}
                                    disabled
                                />
                            </div>
                            <div className="k-mb-2">
                                <Field
                                    disabled
                                    name="cnpj"
                                    placeholder="CNPJ"
                                    icon="k-i-parameters-unknown"
                                    mask="00.000.000/0000-00"
                                    validator={cnpjValidator}
                                    component={MaskedInput}
                                />
                            </div>
                            <div className="buttonPositions">
                                    {/* <Button themeColor="primary" type="submit" disabled={!formProps.modified || !formProps.valid}>
                                        ENVIAR
                                    </Button> */}
                                    <Button fillMode="outline" onClick={() => { history.push('/addresses'); }}>
                                        MEUS ENDEREÇOS
                                    </Button>
                            
                            </div>
                        </FormElement>
                    )}
                />
            {(subscription?.status.toUpperCase() !== "ATIVA" && selected !== null) && (
           <div className="text-subscription">
           Você já possui todas as informações para concluir sua assinatura. <br/> 
           <Button fillMode='link' className='link_subscription' onClick={() => { history.push('/subscription'); }}>Clique aqui</Button> para ir para a tela de pagamento.
                </div>
          )}
                  
            </MainModal>
        </div>
    )
}

export default ProfilePage;
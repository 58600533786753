/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useUserContext } from "../../context/authentication";
import { useReportContext } from "../../context/workspace";
import history from "../../routes/history";
import Dropdown from "../dropdown";

import "./styles.scss";
import { useChannelContext } from "../../context/channel";
import IEmbedPowerBIObject from "../../interfaces/embedPowerBIObject";
import IChannel from "../../interfaces/channel";
import IWorkspace from "../../interfaces/workspace";
import { storageParams, userType } from "../../config/enum";
import { useSubscriptionContext } from "../../context/subscription";

const AppBar: React.FunctionComponent<any> = function ({ children }) {
  const { authenticatedUser: user } = useUserContext();
  const {
    channels,
    selectChannel = () => {},
    selectedChannel,
  } = useChannelContext();
  const {
    selectWorkspace,
    selectedWorkspace,
    workspaceOptions,
    selectEmbedPowerBIObject,
    selectedEmbedPowerBIObject,
    embedPowerBIObjectOptions,
  } = useReportContext();
  const { logout = () => {} } = useUserContext();
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [showWorkspaceOptions, setShowWorkspaceOptions] = useState(false);
  const [showReportOptions, setShowReportOptions] = useState(false);
  const [showChannelOptions, setShowChannelOptions] = useState(false);
  const { subscription } = useSubscriptionContext();

  const formatDate = (input: any) => {
    if (!input) {
      return '';
    }

    if (input !== null && input !== "" && input !== undefined) {
      var datePart = input.match(/\d+/g),
        month = datePart[1],
        day = datePart[2],
        hour = datePart[3],
        minutes = datePart[4];
      return day + "/" + month + " às " + hour + ":" + minutes;
    }
  };

  const handleClickWorkspaceOption = function (workspace: IWorkspace) {
    selectWorkspace(workspace);
    setShowWorkspaceOptions(false);
  };

  const handleClickReportOption = function (report: IEmbedPowerBIObject) {
    selectEmbedPowerBIObject(report);
    setShowReportOptions(false);
  };

  const handleClickChannelOption = function (channel: IChannel) {
    selectChannel(channel);
    setShowChannelOptions(false);
  };

  const handleLogout = async function () {
    logout();
  };

  const redirectToDashboard = useCallback(() => {
    if (channels?.length > 1) {
      history.push("/channel-select");
    } else if (channels?.length === 1) {
      history.push("/dashboard");
    }
  }, [channels?.length]);

  const regexName = (str: any) => {
    if (str !== undefined) {
      var arr = str.split(" ");
      if (arr.length >= 2) {
        var keep = arr[1][0].toUpperCase() !== arr[1][0];
        return arr.slice(0, keep ? 3 : 2).join(" ");
      } else {
        return arr;
      }
    }
  };

  // Redireciona para a dashboard sempre que é alterado o relatório a ser renderizado
  useEffect(() => {
    if (
      location.pathname !== `${process.env.PUBLIC_URL}/dashboard` &&
      selectedEmbedPowerBIObject
    ) {
      history.push("/dashboard");
    }
  }, [selectedEmbedPowerBIObject]);

  const dataFinalAssinatura = useMemo(() => {
    return formatDate(subscription?.dataFinal);
  }, [subscription?.dataFinal]);

  return (
    <div className="app-bar-container">
      {/* Canais e Relatórios */}
      {!user?.senhaInicial && (
        <div className="menu-items-container flex-start">
          {/* Canais */}
          {channels && channels.length > 0 && (
            <Dropdown
              className="menu-item"
              icon="k-i-grid-layout"
              label={selectedChannel?.nome || "Selecionar canal"}
              show={showChannelOptions}
              toggleShow={() => setShowChannelOptions(!showChannelOptions)}
              handleClose={() => setShowChannelOptions(false)}
            >
              <ul className="options-container">
                {channels.map((channel) => (
                  <li
                    key={channel.idCanal}
                    onClick={() => handleClickChannelOption(channel)}
                    className={
                      channel.idCanal === selectedChannel?.idCanal
                        ? "selected"
                        : ""
                    }
                  >
                    {channel.nome}
                  </li>
                ))}
              </ul>
            </Dropdown>
          )}

          {/* Workspaces */}
          {workspaceOptions && workspaceOptions.length > 0 && (
            <Dropdown
              className="menu-item"
              icon="k-i-paste-plain-text"
              label={selectedWorkspace?.name || ""}
              show={showWorkspaceOptions}
              toggleShow={() => setShowWorkspaceOptions(!showWorkspaceOptions)}
              handleClose={() => setShowWorkspaceOptions(false)}
            >
              <ul className="options-container">
                {workspaceOptions.map((workspace) => (
                  <li
                    key={workspace.id}
                    onClick={() => handleClickWorkspaceOption(workspace)}
                    className={
                      workspace.id === selectedWorkspace?.id ? "selected" : ""
                    }
                  >
                    {workspace.name}
                  </li>
                ))}
              </ul>
            </Dropdown>
          )}

          {/* Reports */}
          {embedPowerBIObjectOptions && embedPowerBIObjectOptions.length > 0 && (
            <Dropdown
              className="menu-item"
              icon="k-i-align-bottom-element"
              label={selectedEmbedPowerBIObject?.name || ""}
              show={showReportOptions}
              toggleShow={() => setShowReportOptions(!showReportOptions)}
              handleClose={() => setShowReportOptions(false)}
            >
              <ul className="options-container">
                {embedPowerBIObjectOptions.map((report) => (
                  <li
                    key={report.id}
                    onClick={() => handleClickReportOption(report)}
                    className={
                      report.id === selectedEmbedPowerBIObject?.id
                        ? "selected"
                        : ""
                    }
                  >
                    {report.name}
                  </li>
                ))}
              </ul>
            </Dropdown>
          )}
        </div>
      )}

      {/* Logo */}
      <div className="logo-container">
        <img
          src="./content/channel/image/logo_power_vision_horizontal_1.png"
          alt="Logo canal selecionado"
          className="channel-logo"
          onClick={redirectToDashboard}
        />
      </div>

      {/* Opções Usuário */}
      <div className="menu-items-container flex-end">
        <Dropdown
          className="dropDownMenuUser"
          icon="k-i-user"
          label={regexName(user?.nome) || ""}
          show={showUserOptions}
          toggleShow={() => setShowUserOptions(!showUserOptions)}
          handleClose={() => setShowUserOptions(false)}
        >
          <ul className="options-container">
            {!user?.senhaInicial && (
              <li
                onClick={() => {
                  history.push("/subscription");
                  setShowUserOptions(false);
                }}
                className="item"
              >
                Minha Assinatura
              </li>
            )}
            {!user?.senhaInicial && (
              <li
                onClick={() => {
                  history.push("/profile");
                  setShowUserOptions(false);
                }}
                className="item"
              >
                Meus Dados
              </li>
            )}
            <li
              onClick={() => {
                history.push("/change-password");
                setShowUserOptions(false);
              }}
              className="item"
            >
              Alterar Senha
            </li>
            {sessionStorage.getItem(storageParams.userType) ===
              userType.admin && (
              <li
                onClick={() => {
                  history.push("/user-select");
                  setShowUserOptions(false);
                }}
                className="item"
              >
                Alterar Usuário
              </li>
            )}
            {selectedChannel && !user?.senhaInicial && (
              <li
                onClick={() => {
                  history.push("/about");
                  setShowUserOptions(false);
                }}
                className="item"
              >
                Sobre
              </li>
            )}
            {channels.length > 1 && !user?.senhaInicial && (
              <li
                onClick={() => {
                  history.push("/channel-select");
                  setShowUserOptions(false);
                }}
                className="item"
              >
                Trocar Canal
              </li>
            )}
            <li onClick={handleLogout} className="item">
              Sair
            </li>
          </ul>
        </Dropdown>
        <div className="subs-options flex-end">
          <span className="text-primary">
            {dataFinalAssinatura && `Assinatura vigente até ${dataFinalAssinatura}`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AppBar;

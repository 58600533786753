import api from '../api';

const getAceiteUsuario = async function (idEmpresa?: number) {
    return api.get(`/logAceiteUsuario?IdEmpresa=${idEmpresa}`);
}

const insertAceiteUsuario = async function (idEmpresa?: number) {
    return api.post(`/logAceiteUsuario`, {
        IdEmpresa: idEmpresa
    });
}

const logAceiteUsuarioService = {
    getAceiteUsuario,
    insertAceiteUsuario
}

export default logAceiteUsuarioService;

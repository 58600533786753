import React from 'react';
import IPage from '../../interfaces/page';
import history from '../../routes/history';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import MainModal from '../../components/mainModal';
import { requiredValidator } from '../../utils/validators';
import TextInput from '../../components/textInput';
import sUser from '../../services/userService';
import './styles.scss';
import PW from '../../assets/image/LogoPW.png'
import PCA from '../../assets/image/LogoPCA.png'

const ForgotPasswordPage: React.FunctionComponent<IPage> = function (props: any) {

    const handleSubmit = async function (data: any) {
        if (await sUser.forgotPassoword(data.email)) {
            history.push('/');
        }
    }

    return (
        <div className="container-page">
            <img className="logoPW" alt="Logo Power Vision" src={PW}></img>
            <img className="logoPCA" alt="Logo PCA" src={PCA}></img>
            <MainModal title="Esqueci Minha Senha">
                <div className="text-info">
                    Informe seu e-mail e solicite uma senha provisória.
                </div>
                <Form
                    onSubmit={handleSubmit}
                    render={(formProps) => (
                        <FormElement>
                            <Field
                                name="email"
                                type="email"
                                validator={requiredValidator}
                                icon="k-i-email"
                                component={TextInput}
                            />
                            <div className="container-links">
                                <div className="k-m-4">
                                    <div className="text-primary caption">
                                        <div className="link-button" onClick={() => history.push('/')}>
                                            Retornar ao login
                                        </div>
                                    </div>
                                </div>
                                <Button themeColor="primary" type="submit" disabled={!formProps.modified || !formProps.valid}>
                                    ENVIAR
                                </Button>
                            </div>
                        </FormElement>
                    )}
                />
            </MainModal>
        </div>
    )
}

export default ForgotPasswordPage;
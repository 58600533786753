import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { FormElement } from '@progress/kendo-react-form';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import sInvitation from '../../services/invitationService';
import IPage from '../../interfaces/page';
import history from '../../routes/history';
import MainModal from '../../components/mainModal';
import FormDialog from '../../components/formDialog';
import PW from '../../assets/image/LogoPW.png'
import PCA from '../../assets/image/LogoPCA.png'


const InvitationPage: React.FunctionComponent<IPage> = function (props: any) {
    const [codigoSeguranca, setCodigoSeguranca] = useState<string>("");
    const [motivoRecusa, setMotivoRecusa] = useState<string>("");
    const [showMotivoRecusaModal, setShowRefuseModal] = useState<boolean>(false);
    const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false);
    const { idConvite } = useParams<any>();

    const acceptInvitation = async function () {
        closeAcceptModal();
        await sInvitation.acceptInvitation(idConvite, codigoSeguranca);
        history.push('/');
    }
    const refuseInvitation = async function () {
        closeRefuseModal();
        await sInvitation.refuseInvitation(idConvite, codigoSeguranca, motivoRecusa);
        history.push('/');
    }
    const openAcceptModal = function () {
        setShowAcceptModal(true);
    }
    const openRefuseModal = function () {
        setShowRefuseModal(true);
    }
    const closeAcceptModal = function () {
        setShowAcceptModal(false);
    }
    const closeRefuseModal = function () {
        setShowRefuseModal(false);
    }

    return (
        <div className="container-page">
            <img className="logoPW" alt="Logo Power Vision" src={PW}></img>
            <img className="logoPCA" alt="Logo PCA" src={PCA}></img>
            <MainModal title="Convite">
                <div className="text-info">
                    Informe o código de segurança do convite recebido via e-mail
                </div>
                <FormElement>
                    <div className="k-mb-2">
                        <span className="k-textbox k-space-left">
                            <Input placeholder="Codigo de segurança" value={codigoSeguranca} onChange={e => setCodigoSeguranca(e.target.value as string)} maxLength={10}/>
                            <i className="k-icon-input k-icon k-i-lock">&nbsp;</i>
                        </span>
                    </div>
                    <div className="text-center">
                        <div className="k-m-4">
                            <div className="text-primary caption">
                                <div className="link-button" onClick={() => history.push('/')}>
                                    Ir para tela de login
                                </div>
                            </div>
                        </div>
                        <Button themeColor="primary" type="button" onClick={openRefuseModal} className="button-danger k-mr-2" disabled={codigoSeguranca.trim() === ""}>
                            RECUSAR
                        </Button>
                        <Button themeColor="primary" type="button" onClick={openAcceptModal} disabled={codigoSeguranca.trim() === ""}>
                            ACEITAR
                        </Button>
                    </div>
                </FormElement>
            </MainModal>
            <FormDialog isVisible={showAcceptModal} closeModal={closeAcceptModal} confirm={acceptInvitation} title="Confirmação de Aceite">
                <div className="text-info">
                    Tem certeza que deseja aceitar o convite?
                </div>
            </FormDialog>
            <FormDialog isVisible={showMotivoRecusaModal} closeModal={closeRefuseModal} confirm={refuseInvitation} title="Confirmação de Recusa">
                <FormElement>
                    <div className="k-mb-2">
                        <TextArea maxLength={200} placeholder="Motivo recusa" value={motivoRecusa} onChange={e => setMotivoRecusa(e.target.element.current?.value as string)} />
                    </div>
                </FormElement>
            </FormDialog>
        </div>
    )
}

export default InvitationPage;
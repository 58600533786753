import React, { useState, useEffect } from "react";
import { models, Report, Embed, service } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import "./styles.scss";
import { useReportContext } from "../../context/workspace";
import { useUserContext } from "../../context/authentication";
import { useChannelContext } from "../../context/channel";
import history from "../../routes/history";

const DashboardPage = function () {
    const { selectedEmbedPowerBIObject, refreshTokens } = useReportContext();
    const { viewedUser } = useUserContext();
    const { selectedWorkspace } = useReportContext();
    const { selectedChannel } = useChannelContext();

    useEffect(() => {
        const key = setInterval(function () {
            if (
                viewedUser &&
                viewedUser.id &&
                selectedChannel &&
                selectedWorkspace
            ) {
                refreshTokens(
                    viewedUser.id,
                    selectedChannel.idCanal,
                    selectedWorkspace.id
                );
            }
        }, 60 * 60 * 1000); // 60 minutes of interval;

        return () => {
            clearInterval(key);
        };
    }, [refreshTokens, selectedChannel, selectedWorkspace, viewedUser]);

    useEffect(() => {
        if (
            !selectedEmbedPowerBIObject ||
            !selectedChannel ||
            !selectedWorkspace
        ) {
            history.push("/channel-select");
        }
    }, [selectedChannel, selectedEmbedPowerBIObject, selectedWorkspace]);

    // PowerBI Report object (to be received via callback)
    const [report, setReport] = useState<Report>();

    // Report config useState hook
    // Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
    const [reportConfig, setReportConfig] =
        useState<models.IReportEmbedConfiguration>({
            type: "report",
            embedUrl: undefined,
            tokenType: models.TokenType.Embed,
            accessToken: undefined,
        });

    // Altera relatório a ser renderizado
    useEffect(() => {
        setReportConfig((prevState) => ({
            ...prevState,
            embedUrl: selectedEmbedPowerBIObject?.embedUrl,
            accessToken: selectedEmbedPowerBIObject?.accessToken,
        }));
    }, [selectedEmbedPowerBIObject]);

    useEffect(() => {
        report?.reload();
    }, [report, reportConfig]);

    // Map of event handlers to be applied to the embedding report
    const eventHandlersMap = new Map([
        ["loaded", function () {}],
        ["rendered", function () {}],
        [
            "error",
            function (event?: service.ICustomEvent<any>) {
                if (event) {
                    console.error(event.detail);
                }
            },
        ],
    ]);

    return (
        <div className="container-page-dashboard padding-header">
            <PowerBIEmbed
                embedConfig={reportConfig}
                eventHandlers={eventHandlersMap}
                cssClassName="powerbi-container"
                getEmbeddedComponent={(embedObject: Embed) => {
                    setReport(embedObject as Report);
                }}
            />
        </div>
    );
};

export default DashboardPage;

import React, { useEffect } from "react";
import IPage from "../../interfaces/page";
import "./styles.scss";
import MainModal from "../../components/mainModal";
import { getInitials } from "../../utils/string";

import { useCallback } from "react";
import { useChannelContext } from "../../context/channel";
import IChannel from "../../interfaces/channel";

const ChannelSelectPage: React.FunctionComponent<IPage> = function (
    props: any
) {
    const { channels, selectChannel, clearChannel } = useChannelContext();

    useEffect(() => {
        if (channels && channels.length > 0) {
            if (channels.length === 1) {
                const channel = channels[0];
                selectChannel(channel);
            } else if (channels.length > 1) {
                selectChannel(undefined);
            }
        }
    }, [channels, clearChannel, selectChannel]);

    const redirectToDashboard = useCallback(
        (channel: IChannel) => {
            selectChannel(channel);
        },
        [selectChannel]
    );

    return (
        <div className="container-page channel-select-page padding-header">
            <MainModal title="Selecione o Canal">
                <div className="channel-container">
                    {channels.map((channel) => (
                        <div
                            key={channel.idCanal}
                            className="channel-item"
                            onClick={() => redirectToDashboard(channel)}
                        >
                            <div className="avatar">
                                {getInitials(channel.nome)}
                            </div>
                            <div className="label">{channel.nome}</div>
                        </div>
                    ))}
                </div>
            </MainModal>
        </div>
    );
};

export default ChannelSelectPage;

import React, { useMemo } from "react";
import axios from "axios";
import api from "./index";
import history from "../routes/history";
import { useUserContext } from "../context/authentication";
import useOnlineStatus from "../hooks/useOnlineStatus";
import Message from "../config/message";
import { httpStatusCode as HttpStatusCode } from "../config/enum";
import { useNotificationContext } from "../context/notification";
import { buildValidationErros } from "./utils";
import { storageParams } from "../config/enum";
import version from "../utils/version";
// import { useMessage } from '../messages/context';

// Possibilita utilizar os React Hooks no tratamento de exceções.
const WithAxios: React.FunctionComponent<{}> = function (props) {
    const { setAuthenticatedUser: setUser = () => {} } = useUserContext();
    const { showLoading, hideLoading, showMessage } = useNotificationContext();
    const { logout=()=>{} } = useUserContext();
    // const { showLoading, hideLoading, showAlertApi } = useMessage();
    const isOnline = useOnlineStatus();

    useMemo(() => {
        api.interceptors.request.use(
            function (config) {
                if (!isOnline) {
                    return Promise.reject(new axios.Cancel("isOffline"));
                }

                // CASO X-Loading-Screen ESTEJA MARCADO COMO 'false' NÃO IRA CARREGAR A TELA
                if (config.headers["x-loading-screen"] !== false) {
                    showLoading();
                }
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        api.interceptors.response.use(
            function (response) {
                hideLoading();
                // Update version
                const versionFromHeader = response.headers["x-min-version"];
                if (version.updateAppVersion(versionFromHeader)) {
                    return Promise.reject("Versão do app desatualizada.");
                }

                // Atualiza Token JWT
                const tokenJwt = response.headers["x-access-token"];
                const csrfTkn = response.headers["x-csrf-token"];
                if (tokenJwt) {
                    api.defaults.headers.Authorization = `Bearer ${tokenJwt}`;
                    sessionStorage.setItem(storageParams.tokenJwt, tokenJwt); // Salva token JWT no session storage
                    setUser((prevState) => ({
                        ...prevState,
                        isAuthenticated: true,
                    }));
                }

                if (csrfTkn) {
                    api.defaults.headers.get["X-Csrf-Token"] = csrfTkn;
                    api.defaults.headers.post["X-Csrf-Token"] = csrfTkn;
                    api.defaults.headers.put["X-Csrf-Token"] = csrfTkn;
                    api.defaults.headers.delete["X-Csrf-Token"] = csrfTkn;
                    api.defaults.headers.patch["X-Csrf-Token"] = csrfTkn;
                }

                if (response.data.message) {
                    showMessage(
                        response.data.message,
                        response.data.status || "success"
                    );
                }

                return Promise.resolve(response);
            },
            function (error) {
                hideLoading();
                if (error.message === "isOffline") {
                    return Promise.reject(error);
                }

                let message = Message.defaultError;
                let status: "error" | "info" | "warning" | "success" = "error";

                if (error.code === "ECONNABORTED") {
                    message = Message.serverTimeout;
                }

                if (error.response) {
                    switch (error.response.status) {
                        case HttpStatusCode.Unauthorized:
                        case HttpStatusCode.Forbidden:
                            if (
                                error.response.data?.data
                                    ?.expiredSubscription === true
                            ) {
                                history.push("/subscription");
                                return Promise.reject(error);
                            } else {
                                api.defaults.headers.Authorization = undefined;
                                setUser((prevState) => {
                                    return {
                                        ...prevState,
                                        isAuthenticated: false,
                                    };
                                });

                                logout();
                            }
                            break;

                        case HttpStatusCode.BusinessException:
                            status = "warning";
                            break;
                    }

                    // Recupera mensagem enviada pela API
                    if (error.response.data && error.response.data.message) {
                        message = error.response.data.message;
                    }

                    if (
                        ["info", "warning", "success", "error"].includes(
                            error.response.data.status
                        )
                    ) {
                        status = error.response.data.status;
                    }

                    // Erros de validação
                    if (error.response.data.errors) {
                        message = buildValidationErros(
                            error.response.data.errors
                        );
                        status = "error";
                    }

                    showMessage(message, status);
                    return Promise.reject(error);
                }

                showMessage(message, status);
                return Promise.reject(error);
            }
        );
    }, [hideLoading, isOnline, logout, setUser, showLoading, showMessage]);

    return <>{props.children}</>;
};

export default WithAxios;

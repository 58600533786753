import React, { useState, useEffect, useCallback } from "react";
import MainModal from "../../components/mainModal";
import IPayment from "../../interfaces/payment";
import history from "../../routes/history";
import CheckoutButton from "../../components/mercadoPago/checkoutButton";
import sSubscription from "../../services/subscriptionService";
import sPayment from "../../services/pagamentoService";
import "./styles.scss";
import PaymentHistoryDialog from "./PaymentHistoryDialog";
import { moneyFormat } from "../../utils/masks";
import AlertModal from "../../components/alertModal";
import { UIDate, UIEnum } from "../../components/formatter";
import parametroSistemaService from "../../services/parametroSistemaService";
import { useChannelContext } from "../../context/channel";
import { useUserContext } from "../../context/authentication";
import { Button } from "@progress/kendo-react-buttons";
import { useSubscriptionContext } from "../../context/subscription";

let renovou = false;

const SubscriptionPage = function () {
  const [filtroInicial, setFiltroInicial] = useState<Date | undefined>(
    undefined
  );
  const [filtroFinal, setFiltroFinal] = useState<Date | undefined>(undefined);
  const { subscription, updateSubscription } = useSubscriptionContext();
  const [payment, setPayment] = useState<IPayment>();
  const [missingInfoMessage, setMissingInfoMessage] =
    useState<JSX.Element | null>(null);
  const [openMissingInfo, setOpenMissingInfo] = useState<boolean>(false);
  const [paymentHistoryDialogIsOpen, setPaymentHistoryDialogIsOpen] =
    useState<boolean>(false);
  const [paymentHistory, setPaymentHistory] = useState<IPayment[]>([]);
  const [nfseHistory, setNfseHistory] = useState<IPayment[]>([]);
  const [mercadoPagoConfig, setMercadoPagoConfig] = useState<any>();
  const { channels, loadChannels } = useChannelContext();
  const { viewedUser } = useUserContext();

  const refreshSubscriptionStatus = useCallback(() => {
    if (viewedUser) {
      sSubscription.getSubscription().then((resp) => {
        updateSubscription({
          idAssinatura: resp.data.idAssinatura,
          status: resp.data.status,
          dataInicial: resp.data.dataInicial,
          dataFinal: resp.data.dataFinal,
          periodoCarenciaPagamento: resp.data.periodoCarenciaPagamento,
          dataFinalCarenciaPosExpiracao: resp.data.dataFinalCarenciaPosExpiracao
        });
        if (resp.data.preferenceId) {
          setPayment({
            pagamentoId: resp.data.pagamentoId,
            preferenceId: resp.data.preferenceId,
            url: resp.data.urlPagamento,
          });
        }
      });
    }
  }, [updateSubscription, viewedUser]);

  const abrirModalMissingInfo = () => {
    setOpenMissingInfo(!openMissingInfo);
  };

  const goToProfile = () => {
    setMissingInfoMessage(null);
    history.push("/addresses");
  };
  const rpsDownlod = (pagamentoId?: number) => {
    if (nfseHistory.find((x) => x.pagamentoId === pagamentoId)) {
      var nfse = nfseHistory.find((x) => x.pagamentoId === pagamentoId);
      if (nfse?.rpsurl !== undefined) {
        return (
          <a
            href={nfse?.rpsurl}
            className="subscription-payment-rps"
            rel="noreferrer"
            target="_blank"
          >
          <span className="tooltipnfse" data-tooltipnfse="Baixar NFSe">
            <span className="k-icon k-i-file-txt">
          </span>
          
            </span>
          </a>
        );
      }
    }
  };
  const handleMissingInfo = (missingInfoArray: Array<string>) => {
    if (missingInfoArray?.length > 0) {
      var missingInfo = (
        <div className="payment-missing-info-alert">
          <span>
            Notamos que <b>há informações faltando</b> em seu cadastro e que são
            necessárias para o pagamento ou emissão da Nota Fiscal de Serviço.
          </span>
          <br />
          <br />
          <span>As informações que faltam são:</span>
          <ul>
            {missingInfoArray.map((e) => {
              return <li>{e}</li>;
            })}
          </ul>
          <span>
            <b>Clique no botão abaixo</b> para prosseguir com o registro das
            informações.
          </span>
        </div>
      );
      setMissingInfoMessage(missingInfo);
    }
  };

  const renovarAssinatura = useCallback(function () {
    renovou = true;
    sSubscription.renewSubscription().then((resp) => {
      setPayment({
        preferenceId: resp.data.data.preferenceId,
        url: resp.data.data.urlPagamento,
      });
    });
  }, []);

  useEffect(() => {
    renovou = false;
  }, []);

  // Carrega canais ao reativar assinatura
  useEffect(() => {
    if (
      subscription?.status?.toUpperCase() === "ATIVA" &&
      (!channels || channels.length === 0) &&
      viewedUser?.id
    ) {
      loadChannels(viewedUser.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadChannels, subscription, viewedUser]);

  // Atualiza status da assinatura ao carregar tela
  useEffect(() => {
    refreshSubscriptionStatus();
  }, [refreshSubscriptionStatus]);

  // Atualiza status da assinatura a cada 5 segundos
  useEffect(() => {
    const key = setInterval(refreshSubscriptionStatus, 5000);
    return () => clearInterval(key);
  }, [refreshSubscriptionStatus]);

  useEffect(() => {
    if (
      subscription?.status &&
      ["AGUARDANDO RENOVAÇÃO", "EXPIRADA"].includes(
        subscription?.status?.toUpperCase()
      ) &&
      !renovou
    ) {
      renovarAssinatura();
    }
  }, [renovarAssinatura, subscription]);

  useEffect(() => {
    if (viewedUser) {
      let isReactEventSubscribed = true;

      const fetchEnderecos = async () => {
        const paymentsResponse = await sSubscription.getPayments(
          viewedUser.id,
          filtroInicial,
          filtroFinal
        );
        const dataPagamentos = paymentsResponse.data;
        const dataNfse = dataPagamentos;
        const missingInfoResponse = await sPayment.getMissingInfo();
        const dataMissingInfo = missingInfoResponse.data;
        if (isReactEventSubscribed) {
          setPaymentHistory(dataPagamentos);
          setNfseHistory(dataNfse);
          handleMissingInfo(dataMissingInfo ?? []);
        }
      };
      fetchEnderecos().catch();
      return () => {
        isReactEventSubscribed = false;
      };
    }
  }, [filtroFinal, filtroInicial, subscription?.idAssinatura, viewedUser]);

  useEffect(() => {
    if (viewedUser) {
      parametroSistemaService.getConfigMercadoPago().then((resp) => {
        if (resp.data && resp.data.publicKey) {
          setMercadoPagoConfig({
            accessToken: resp.data.accessToken,
            publicKey: resp.data.publicKey,
          });
        }
      });
    }
  }, [viewedUser]);
  const formatDate = (input: any) => {
    if (!input) {
      return '';
    }

    var datePart = input.match(/\d+/g),
    year = datePart[0], 
    month = datePart[1], day = datePart[2], hour = datePart[3], minutes = datePart[4];
    return day+'/'+month+'/'+year+' às '+hour+':'+minutes;
  }

  const redirectToDashboard = useCallback(() => {
    if (channels?.length > 1) {
      history.push("/channel-select");
    } else if (channels?.length === 1) {
      history.push("/dashboard");
    }
  }, [channels?.length]);

  return (
    <>
      <div className="container-page subscription-page padding-header">
        <MainModal title="Minha Assinatura">
          <div className="subscription-container">
            {subscription?.dataInicial && (
              <div>
                <p className="subscription-title">Período de Vigência:</p>

                <div className="subscription-item alignitems-center">
                  <i className={`k-icon k-i-calendar`} />

                  <span className="text-primary">
                    <b>
                      <UIDate>{subscription?.dataInicial}</UIDate>
                    </b>{" "}
                    até{" "}
                    <b>
                    {<UIDate>{subscription?.dataFinal}</UIDate>}
                    </b>
                  </span>
                </div>
              </div>
            )}

            {subscription?.status?.toUpperCase() === "ATIVA" ? (
              <div className="subscription-item alignitems-center">
                <i className={`k-icon k-i-check-circle text-success`} />

                <span className="text-primary">
                  Sua assinatura encontra-se ativa.{" "}
                  <b>
                    <br />
                    <span className="link-button" onClick={redirectToDashboard}>
                      Clique aqui para visualizar seus relatórios
                    </span>
                  </b>
                </span>
              </div>
            ) : (
              <>
                <div className="subscription-item">
                  <i className={`k-icon k-i-warning text-error`} />

                  <span className="text-primary">
                    <span>
                      {" "}
                      <b> Sua assinatura expirou. </b>{" "}
                    </span>
                    <br />
                    {subscription?.dataFinalCarenciaPosExpiracao ? (
                      <span>
                      {" "}
                      Para continuar acessando as informações do portal é
                      necessário realizar o pagamento até o dia <UIDate>{subscription?.dataFinalCarenciaPosExpiracao}</UIDate>
                    </span>
                    ) : (
                      <span>
                        {" "}
                        Para continuar acessando as informações do portal é
                        necessário realizar o pagamento{" "}
                      </span>
                    )}
                  </span>
                </div>
                <div className="subscription-fields-footer">
                  {missingInfoMessage ? (
                    <div className="subscription-missingInformation">
                      <div>
                        <i className="k-icon k-i-x-circle"></i>
                        Há pendências no seu cadastro que o impedem de pagar a
                        assinatura.
                      </div>
                      <span
                        className="link-button"
                        onClick={abrirModalMissingInfo}
                      >
                        <b> Clique aqui para visualizar</b>
                      </span>
                    </div>
                  ) : (
                    payment?.preferenceId &&
                    mercadoPagoConfig?.publicKey &&
                    ["AGUARDANDO PAGAMENTO", "PROBLEMA PAGAMENTO"].includes(subscription?.status?.toUpperCase() || '') && (
                      <React.Fragment>
                        <div
                          className="subscription-item justifycontent-spacebetween k-mt-2"
                          aria-disabled={true}
                        >
                          <CheckoutButton
                            preferenceId={payment.preferenceId}
                            publicKey={mercadoPagoConfig.publicKey}
                          />
                          {subscription?.periodoCarenciaPagamento && (
                            <Button className="btn-secondary" onClick={redirectToDashboard}>
                              CONTINUAR E PAGAR DEPOIS
                            </Button>
                          )}
                        </div>
                      </React.Fragment>
                    )
                  )}
                </div>
                <>
                  {subscription?.status?.toUpperCase() === "AGUARDANDO APROVAÇÃO" && (
                    <div className="text-primary text-align-center k-p-4">
                      <b>Pagamento já realizado, aguardando aprovação do Mercado Pago.</b>
                    </div>
                  )}
                  {subscription?.status?.toUpperCase() === "PROBLEMA PAGAMENTO" && (
                    <div className="text-primary text-align-center k-p-4">
                      <b>Seu pagamento foi rejeitado pelo Mercado Pago, por favor, tente novamente com outra forma de pagamento.</b>
                    </div>
                  )}
                </>
              </>
            )}
          </div>

          {paymentHistory.length === 0 ? null : (
            <>
              <div className="divider" />
              <div className="subscription-container">
                <p className="subscription-title">
                  Histórico recente de pagamentos:
                </p>

                <div className="subscription-item flexdirection-column">
                  {paymentHistory.slice(0,3).map((item, index) => {
                    return (
                      <div
                        className="subscription-payment text-primary"
                        key={index}
                      >
                        <div className="subscription-payment-text">
                          {rpsDownlod(item.pagamentoId)}

                                                        <span>
                                                            <p>
                                                                {
                                                                    <UIEnum>
                                                                        {
                                                                            item.formaPagamento
                                                                        }
                                                                    </UIEnum>
                                                                }
                                                            </p>
                                                            <span className="subscription-payment-date">
                                                                 {
                                                                      formatDate(item.dataPagamento)
                                                                 }
                                                            </span>
                                                        </span>
                                                    </div>

                        <span>
                          {" "}
                          <b> {moneyFormat(item.valor, "R$")} </b>{" "}
                        </span>
                      </div>
                    );
                  })}
                </div>

                <div
                  className="subscription-item text-primary alignitems-center justifycontent-flexend k-mt-2 cursor-pointer"
                  onClick={(e) => setPaymentHistoryDialogIsOpen(true)}
                >
                  <i className={`k-icon k-i-preview`} />

                  <span>Ver histórico completo de pagamentos</span>
                </div>
              </div>
            </>
          )}
        </MainModal>
      </div>

      <AlertModal
        onClose={goToProfile}
        onClickActionConfirm={goToProfile}
        alertMessage={missingInfoMessage || ""}
        open={openMissingInfo}
        okButtonMessage="ir para meus dados"
        height={350}
      />
      <PaymentHistoryDialog
        isVisible={paymentHistoryDialogIsOpen}
        paymentHistory={paymentHistory}
        nfseHistory={nfseHistory}
        closeDialog={() => setPaymentHistoryDialogIsOpen(false)}
      />
    </>
  );
};

export default SubscriptionPage;

import { FunctionComponent } from 'react';
import { translateEnum } from '../../utils/translate';

interface ICurrencyProps {
    showSign?: boolean;
}

interface IDecimalProps {
    showSign?: boolean;
    precision?: number;
}

interface IDateProps {
    showTime?: boolean;
}

const UICurrency: FunctionComponent<ICurrencyProps> = function ({ children, showSign = false }) {
    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        signDisplay: showSign ? 'always' : 'never',
        useGrouping: true,
        notation: 'standard'
    });

    return isNaN(Number(children)) ? (
        <>{children}</>
    ) : (
        <>{currencyFormatter.format(Number(children))}</>
    );
};

const UIDecimal: FunctionComponent<IDecimalProps> = function ({
    children,
    showSign = false,
    precision = 2
}) {
    const decimalFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        signDisplay: showSign ? 'always' : 'never',
        useGrouping: true,
        minimumFractionDigits: precision,
        notation: 'standard'
    });

    return isNaN(Number(children)) ? (
        <>{children}</>
    ) : (
        <>{decimalFormatter.format(Number(children))}</>
    );
};

const UIDate: FunctionComponent<IDateProps> = function ({ children, showTime = false }) {
    const dateFormatter = new Intl.DateTimeFormat('pt-BR', {
        dateStyle: 'short',
        timeStyle: showTime ? 'medium' : undefined
    });
    const date = Date.parse(children as string);
    return isNaN(date) ? <>{children}</> : <>{dateFormatter.format(new Date(date))}</>;
};

const UIEnum: FunctionComponent = function ({ children }) {
    const transletedValue = translateEnum(children as string);
    return <>{transletedValue}</>;
};

export { UICurrency, UIDate, UIDecimal, UIEnum };

import api from '../api';

const getSubscriptionPayments = async function (assinaturaId: number | undefined) {
    return api.get('/payment', { params: { assinaturaId: assinaturaId }});
}

const getMissingInfo = async function () {
    return api.get('/payment/missinginfo');
}


const paymentService = {
    getSubscriptionPayments,
    getMissingInfo,
}

export default paymentService;

import { useEffect, useState } from "react"
import { useUserContext } from "../context/authentication";
import { useNotificationContext } from "../context/notification";

function useIsAlive() {
    const { logout=()=>{} } = useUserContext();
    const [isAlive, setIsAlive] = useState<boolean>(true);
    const { showMessage } = useNotificationContext();

    useEffect(() => {
        var timeoutTime = 15 * 60 * 1000; // 15 minutes
        var timeoutTimer = setTimeout(() => setIsAlive(false), timeoutTime);

        const handleIsAlive = function() {
            clearTimeout(timeoutTimer);
            setIsAlive(true);
            timeoutTimer = setTimeout(() => { setIsAlive(false); showMessage("Tempo de inatividade excedido. Por favor faça o login novamente para continuar utilizando o sistema.", "warning"); logout() }, timeoutTime);
        }

        window.addEventListener('mousedown', handleIsAlive);
        window.addEventListener('keydown', handleIsAlive);
        window.addEventListener('mousemove', handleIsAlive);

        return () => {
            clearTimeout(timeoutTimer);
            window.removeEventListener('mousedown', handleIsAlive);
            window.removeEventListener('keydown', handleIsAlive);
            window.removeEventListener('mousemove', handleIsAlive);
        };
    }, [logout, showMessage]);
    return isAlive;
}

export default useIsAlive;
import React from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from '@progress/kendo-react-buttons';
import './styles.scss';

interface IAlertModalProps {
    open:boolean;
    alertMessage:string | JSX.Element;
    onClose:() => void;
    onClickActionConfirm:() => void;
    okButtonMessage?:string;
    title?:string;
    cancelButtonMessage?:string;
    onClickActionCancel?:() => void;
    width?:number;
    height?:number;
}

const AlertModal: React.FunctionComponent<IAlertModalProps> = function ({
  open,
  onClose,
  onClickActionConfirm,
  onClickActionCancel,
  cancelButtonMessage,
  alertMessage,
  okButtonMessage = "Ok",
  title = "Alerta",
  width = 500,
  height = 350,
}) {

    if (!open) {
      return null;
    }

    return (
      <Dialog
        title={title}
        onClose={onClose}
        width={width}
        height={height}
        className="pv-alertdialog"
      >
        <p style={{ margin: "25px", textAlign: "center" }}>
          {alertMessage}
        </p>
        <DialogActionsBar>
          <div className="pv-alertdialog-actioncontents">
              {(!cancelButtonMessage || !onClickActionCancel || cancelButtonMessage === "") ? null :
                <Button themeColor="error" style={{ minWidth: '100px', textTransform: 'uppercase' }} onClick={onClickActionCancel}>
                  {cancelButtonMessage}
                </Button>
              }
            <Button themeColor="primary" style={{ minWidth: '100px', textTransform: 'uppercase' }} onClick={onClickActionConfirm}>
              {okButtonMessage}
            </Button>
          </div>
        </DialogActionsBar>
      </Dialog>
    )
}

export default AlertModal;

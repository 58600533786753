import React from 'react';

import './styles.scss';

interface IMainModalProps {
    title:string;
}

const MainModal: React.FunctionComponent<IMainModalProps> = function ({children, title}) {
    return (
        <div className="main-modal-container">
            <div className="k-hbox main-modal-header">
                <div className="title-container">
                    <b>{title}</b>
                </div>
                <div className="title-square-detail"></div>
            </div>


            <div className="main-modal-content">
                {children}
            </div>
        </div>
    )
}

export default MainModal;

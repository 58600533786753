import React, { useState, useEffect } from 'react';
import './styles.scss';
import { FieldRenderProps } from '@progress/kendo-react-form';
import {
    ComboBox,
    ComboBoxFilterChangeEvent,
    ListItemProps,
  } from "@progress/kendo-react-dropdowns";
  import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { Error } from '@progress/kendo-react-labels';

interface ITextInputProps extends FieldRenderProps {
    icon:string;
    placeholder:string;
    validFunc:(value: string) => string;
}

const ItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <span>
        {li.props.children}
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
};


const Combobox = function (fieldRenderProps: ITextInputProps) {
    const { valid, textField, validFunc, value, defaultValue, data, validationMessage, touched, icon, placeholder, ...others } = fieldRenderProps;

    const [comboData, setComboData] = useState(data.slice());
    const filterData = (filter: FilterDescriptor) => {
        const filteredData = data.slice();
        return filterBy(filteredData, filter);
    };

    const filterChange = (event: ComboBoxFilterChangeEvent) => {
        setComboData(filterData(event.filter));
    };

    useEffect(() => {
        if (data instanceof Array) {
            setComboData(data.slice());
        }
      return () => {}
    }, [data])

    return (
        <div className="text-input-container">
            <span className="k-textbox k-space-left">
                <i className={'k-p-3 k-icon-input k-icon ' + icon}/>
                <ComboBox
                    className="combobox-custom"
                    popupSettings={{ className: "combobox-popup-custom endereco-page" }}
                    textField={textField}
                    placeholder={placeholder}
                    value={defaultValue}
                    valid={validFunc(defaultValue ? defaultValue[textField] : null) === ""}
                    filterable={true}
                    onFilterChange={filterChange}
                    itemRender={ItemRender}
                    data={comboData} {...others}  />
            </span>
            {validationMessage !== "" && touched && (
                <Error>{validationMessage}</Error>
            )}
        </div>
    )
}

export default Combobox;

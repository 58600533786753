import React, { useRef, useEffect } from 'react';
import { Popup } from "@progress/kendo-react-popup";

import "./styles.scss";

interface ICustomDropdownProps {
    icon:string;
    label:string;
    toggleShow:()=>void;
    handleClose:()=>void;
    show:boolean;
    className?:string;
}

const CustomDropdown: React.FunctionComponent<ICustomDropdownProps> = function ({ icon, label, toggleShow, handleClose, show, className, children }) {
    const anchor = useRef<HTMLDivElement | null>(null);
    const ref = useRef<HTMLDivElement | null>(null);

    // Lógica para esconder options ao clicar fora do modal, incluindo cliques dentro do iFrame do Power BI.
    useEffect(() => {
        var iframeClicked = false;

        function handleClickOutside(event:any) {
            if ((ref.current && !ref.current.contains(event.target) && !anchor.current?.contains(event.target)) || iframeClicked === true) {
                handleClose();
            }
        }

        function handleBlurIframe() {
            if(iframeClicked){
                handleClose();
            }
        }

        function handleMouseOverIframe() {
            iframeClicked = true;
        }

        function handleMouseOutIfram() {
            iframeClicked = false;
        }

        window.addEventListener('blur', handleBlurIframe);
        document.addEventListener("click", handleClickOutside);

        const iframe = document.getElementsByTagName("iframe")[0];
        iframe?.addEventListener("mouseover", handleMouseOverIframe);
        iframe?.addEventListener("mouseout", handleMouseOutIfram);

        return () => {
            // Unbind the event listener on clean up
            window.removeEventListener("blur", handleBlurIframe);
            document.removeEventListener("click", handleClickOutside);
            iframe?.removeEventListener("mouseover", handleMouseOverIframe);
            iframe?.removeEventListener("mouseout", handleMouseOutIfram);
        };
    }, [ref, handleClose]);

    return (
        <div className={className}>
            <div className="dropdown-container" onClick={toggleShow} ref={anchor}>
                <i className={`k-icon ${icon} k-mr-2`} />
                <div className="menu-label k-mr-2">{label}</div>
                <i className="k-icon k-i-arrow-chevron-down"/>
            </div>
            <Popup anchor={anchor.current} show={show} popupClass={"popup-content"}>
                <div ref={ref}>
                    {children}
                </div>
            </Popup>
        </div>
    );
};

export default CustomDropdown
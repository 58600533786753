import { Button } from "@progress/kendo-react-buttons";
import { useCallback} from "react";
import history from '../../routes/history';
import { useUserContext } from "../../context/authentication";
import './style.scss';
import IUser from "../../interfaces/user";



type ListUserProps = {
itens: ListItemTbProps[];
}

type ListItemTbProps = {
    nome: string;
    email: string;
    usuarioPermissao: any;
    userIdentityName: string;
    idUsuario: number;
    tipo: string;
    }

const UserTable: React.FunctionComponent<ListUserProps> = ({itens}) => {
const { setViewedUser} = useUserContext();

const redirectToDashboard = useCallback ((user: IUser) => {
    setViewedUser!(user);
    history.push('/channel-select');
}, [setViewedUser]);


  const construirTabelaUsuario = () => {

      if (itens.length > 0) {
        return (
            <table className="tableUser" cellSpacing={0}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>User Identity</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                {
                    itens.map((item) =>{
            
        return (
           
            <tr key={item.idUsuario}>
                <td className="text-center"> </td>
                <td> {item.nome} </td>
                <td> {item.email} </td>
                <td> {item.usuarioPermissao[0]?.userIdentityName} </td>
                <td>
                <div className="btnEntrar">
                            <Button className="btnClass" type='submit' onClick={() => redirectToDashboard({id: item.idUsuario, nome: item.nome, tipo: item.tipo})}>
                                ENTRAR
                            </Button>
              

        </div>
  </td>
             
            </tr>
        );

                    })
                }
                </tbody>
            </table>
        );
    }

    return (
        <p className="text-center k-mt-8">Nenhum usuário encontrado</p>
    );
}


return (
    <div>

                    <div className="userSelect-table">
                        {
                            construirTabelaUsuario()
                        }
                    </div>
    </div>  
);
}

export default UserTable;
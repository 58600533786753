import React from 'react';

import './styles.scss';

interface IMainModalProps {
    title:string;
}

const UserModal: React.FunctionComponent<IMainModalProps> = function ({children, title}) {
    return (
        <div className="user-modal-container">
            <div className="k-hbox user-modal-header">
                <div className="title-container-user">
                    <b>{title}</b>
                </div>
                <div className="title-square-detail-user"></div>
            </div>


            <div className="user-modal-content">
                {children}
            </div>
        </div>
    )
}

export default UserModal;

import React, { useCallback } from "react";
import IPage from "../../interfaces/page";
import { useUserContext } from "../../context/authentication";
import history from "../../routes/history";
import UserModal from "../../components/userModal";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import "./styles.scss";
import UserTable from "../../components/userTable";
import useTable from "./hooks/useTable";
import { useNotificationContext } from "../../context/notification";

const UserSelectPage: React.FunctionComponent<IPage> = function (props: any) {
  const { userList, getAll } = useTable();
  const { showMessage } = useNotificationContext();
  const handleSubmit = useCallback(
    async (data: any) => {
      if (
        (data.nome !== null && data.nome?.length >= 3) ||
        (data.email !== null && data.email?.length >= 3)
      ) {
        await getAll(data);
      } else {
        showMessage("Confira os dados da busca", "warning");
      }
    },
    [getAll, showMessage]
  );

  const { authenticatedUser, setViewedUser } = useUserContext();

  const redirectToDashboard = useCallback(
    (authenticatedUser) => {
      setViewedUser!(authenticatedUser);
      history.push("/channel-select");
    },
    [setViewedUser]
  );

  return (
    <div className="container-page">
      <UserModal title="Pesquisar Usuário">
        <Form
          onSubmit={handleSubmit}
          render={() => (
            <FormElement className="formUserSelect">
              <div className="containerSearch">
                <div className="textBoxUser">
                  <Field name="nome" component={Input} label={"Nome"} />
                </div>
                <div className="textBoxEmail">
                  <Field name="email" component={Input} label={"E-mail"} />
                </div>
                <div className="btnSearch">
                  <Button fillMode="outline" type="submit">
                    PESQUISAR
                  </Button>
                </div>
              </div>
            </FormElement>
          )}
        />

        <UserTable itens={userList} />

        <div className="k-mt-2 text-center">
          <div
            className="text-primary caption"
            onClick={() => redirectToDashboard(authenticatedUser)}
          >
            <div className="link-button">
              <b>Continuar com o meu usuário</b>
            </div>
          </div>
        </div>
      </UserModal>
    </div>
  );
};

export default UserSelectPage;

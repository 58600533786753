// SDK V2
import React, { useEffect } from 'react';
import { useMercadopago } from 'react-sdk-mercadopago';
import "./styles.scss";

interface ICheckoutButtonProps {
    preferenceId: string;
    publicKey: string;
}

const CheckoutButton:React.FunctionComponent<ICheckoutButtonProps> = function({ preferenceId, publicKey }) {
    const mercadoPago = useMercadopago.v2(publicKey, { locale: 'pt-BR' })

    useEffect(() => {
        if (mercadoPago && preferenceId) {
            mercadoPago.checkout({
                preference: {
                    id: preferenceId
                },
                render: {
                    container: '.cho-container',
                    label: 'PAGAR ASSINATURA'
                }
            })
        }
    }, [mercadoPago, preferenceId])

    return (
        <div>
            <div className="cho-container btn-primary" />
        </div>
    )
}

export default CheckoutButton;
import { useCallback, useState} from "react"
import IUserSelect from "../../../interfaces/userSelect";
import sUser from '../../../services/userService'

const useTable = () => {
    const[userList, setUserList] = useState<IUserSelect[]>([]);
    const getAll = useCallback(async (dados) => {
   
const {status, data} =  await sUser.userList(dados.email, dados.nome);
if(status !== 200) throw new Error()

setUserList(data)
       }, [])
       
       return {
           userList,
           getAll,
       }
}

export default useTable;
import React, { useEffect, useState } from 'react';
import IPage from '../../interfaces/page';
import { useUserContext } from '../../context/authentication';
import api from '../../api';
import history from '../../routes/history';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import MainModal from '../../components/mainModal';
import { requiredValidator } from '../../utils/validators';
import TextInput from '../../components/textInput';
import { useReportContext } from '../../context/workspace';
import { useChannelContext } from '../../context/channel';
import { storageParams } from '../../config/enum';
import PW from '../../assets/image/LogoPW.png'
import PCA from '../../assets/image/LogoPCA.png'

const LoginPage: React.FunctionComponent<IPage> = function (props: any) {
    const { setAuthenticatedUser: setUser = () => { }, login=()=>{} } = useUserContext();
    const { clearEmbedPowerBIObjects, clearWorkspaces } = useReportContext();
    const { clearChannel } = useChannelContext();
    const [initialValues, setInitialValues] = useState<any>();

    useEffect(() => {
        setUser(undefined);
        clearEmbedPowerBIObjects();
        clearWorkspaces();
        clearChannel();
        sessionStorage.clear();
        api.defaults.headers.Authorization = undefined;

        setInitialValues({
            username: localStorage.getItem(storageParams.email) || "",
            password: "",
            remember: localStorage.getItem(storageParams.email) ? true : false,
            trustDevice: localStorage.getItem(storageParams.trustDevice) ? true : false
        });

    }, [clearChannel, clearEmbedPowerBIObjects, clearWorkspaces, setUser]);

    const saveLocalStorageItens = function(data: any) {
        if (data.remember) {
            localStorage.setItem(storageParams.email, data.username);
        } else {
            localStorage.removeItem(storageParams.email);
        }

        if (data.trustDevice) {
            localStorage.setItem(storageParams.trustDevice, "TRUE");
        } else {
            localStorage.removeItem(storageParams.trustDevice);
            localStorage.removeItem(storageParams.tokenConfianca);
        }
    }

    // Faz o login atualizando o contexto do usuário e fazendo os redirecionamentos necessários.
    const handleSubmit = async function (data: any) {
        saveLocalStorageItens(data);
        login(data.username, data.password, data.trustDevice);
    }


    return (
        <div className="container-page">
            <img className="logoPW" alt="Logo Power Vision" src={PW}></img>
            <img className="logoPCA" alt="Logo PCA" src={PCA}></img>
            <MainModal title="Login">
                <Form
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    key={JSON.stringify(initialValues)}
                    render={() => (
                        <FormElement className="k-pt-8">
                            <div className="k-mb-2">
                                <Field
                                    name="username"
                                    type="email"
                                    validator={requiredValidator}
                                    icon="k-i-email"
                                    component={TextInput}
                                />
                            </div>

                            <div className="k-mb-2">
                                <Field
                                    name="password"
                                    type="password"
                                    validator={requiredValidator}
                                    icon="k-i-lock"
                                    component={TextInput}
                                />
                            </div>
                            <div className="text-primary caption k-mb-1">
                                <Field
                                    type="checkbox"
                                    name="remember"
                                    label="Lembrar meu e-mail"
                                    component={Checkbox}
                                />
                            </div>
                            <div className="text-primary caption">
                                <Field
                                    type="checkbox"
                                    name="trustDevice"
                                    label="Confiar neste dispositivo"
                                    component={Checkbox}
                                />
                            </div>
                            <div className="text-center">
                                <Button themeColor="primary" type="submit">
                                    ENTRAR
                                </Button>
                            </div>
                        </FormElement>
                    )}
                />
                <div className="k-mt-2 text-center">
                    <div className="text-primary caption k-mb-1">
                        <span className="link-button" onClick={() => history.push('forgot-password')}>
                            Esqueci minha senha
                        </span>
                    </div>
                    <div className="text-primary caption">
                        <span className="link-button" onClick={() => history.push('register')}>
                            Solicitar Acesso
                        </span>
                    </div>
                </div>
            </MainModal>
        </div>
    )
}

export default LoginPage;

import './styles.scss';

import React from 'react';
import IPage from '../../interfaces/page';
import MainModal from '../../components/mainModal';
import { useTermsAcceptanceContext } from '../../context/termsAcceptance';
import { localDefaultDate, localDefaultTime } from '../../utils/date';
import version from '../../utils/version';


const AboutPage: React.FunctionComponent<IPage> = function (props: any) {
    const { empresaDocumentoAceite, downloadEmpresaDocumentoAceite, logAceiteUsuario } = useTermsAcceptanceContext();

    const gerarComponenteDataAceite = () => {
        if (logAceiteUsuario != null && logAceiteUsuario.dataAceite != null) {
            return (
                <span className="blue-text">
                    Os termos acima foram aceito(s) em {localDefaultDate(logAceiteUsuario.dataAceite)} às {localDefaultTime(logAceiteUsuario.dataAceite)}.
                </span>
            );
        }

        return (
            <span className="blue-text"> Você ainda não aceitou os termos </span>
        );
    }


    return (
        <div className="container-page padding-header">
            <MainModal title="Sobre o Portal Power Vision">
                <div className="text-info">
                    <span> Versão {version.getVersion()} </span>
                    
                    <span>
                        Desenvolvido por <strong> <u> <a rel="noreferrer" href='https://www.pca.com.br/site/' target='_blank'> PCA Engenharia de Software LTDA. </a> </u> </strong>
                    </span>
                </div>

                <div className="text-info">
                    {
                        empresaDocumentoAceite
                            .filter(x => x.documento != null && x.documento.length > 0)
                            .map((doc, index) => {
                            const fileName = doc.nome.toLowerCase().trim().replace(/ /g, "_").normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                            const fullFileName = `${fileName}.${doc.tipo.trim()}`;

                            return (
                                <span 
                                    className="blue-text cursor-pointer"
                                    key={index} 
                                    onClick={_ => downloadEmpresaDocumentoAceite(doc.idEmpresaDocumentoAceite, fullFileName)}
                                >
                                    <strong> <u> {doc.nome} </u> </strong>
                                </span>
                            ); 
                        })
                    }
                </div>

                <div className="text-info">
                    {
                        gerarComponenteDataAceite()
                    }
                </div>

                <div className="text-info">
                    <span> @ 2022 PCA Engenharia de Software LTDA. </span>
                    <span> Todos os direitos reservados. </span>
                </div>
            </MainModal>
        </div>
    )
}

export default AboutPage;
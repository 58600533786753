import React from 'react'
import { Loader } from "@progress/kendo-react-indicators";
import './styles.scss';

interface ILoading {
    show:boolean;
}

const Loading: React.FunctionComponent<ILoading> = function({ show }) {
    return (
        <div className={`loading-container ${show ? '' : 'hide'}`}>
            <Loader type="infinite-spinner" />
        </div>
    )
}

export default Loading;

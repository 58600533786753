import config from "../config/config";
import history from "../routes/history";

const checkUpdates = function (major: number, minor: number, build: number) {
    if (major > config.majorBuildVersion) {
        return true;
    }

    if (major === config.majorBuildVersion) {
        if (minor > config.minorBuildVersion) {
            return true;
        }

        if (minor === config.minorBuildVersion) {
            if (build > config.buildNumber) {
                return true;
            }
        }
    }

    return false;
}

const updateAppVersion = function (version: string) {
    if (!version || version === '') {
        return;
    }

    const [major, minor, build] = version.split('.').map((x) => Number(x));
    if (checkUpdates(major, minor, build)) {
        alert('Uma nova atualização está disponível. Sua aplicação será atualizada.');
        window.location.reload();
        history.push('/');
        return true;
    }
    return false;
}

const version = {
    getVersion: () => `${config.majorBuildVersion}.${config.minorBuildVersion}.${config.buildNumber}`,
    checkUpdates,
    updateAppVersion
}

export default version;